import React, { useEffect, useState, createRef, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";

import Login from "./Login";
import ChatVerNoSdk from "../../service/chat/ChatVerNoSdk";
import ChatVerVod from "../../service/chat/ChatVerVod";
import ChatVip from "../../service/chat/ChatVip";
import LiveHeader from "../../service/live-menu/LiveHeader";
import LiveFooter from "../../service/live-menu/LiveFooter";
import LiveRight from "../../service/live-menu/LiveRight";
import LiveNotice from "../../service/live-menu/LiveNotice";
import LiveNotification from "../../service/live-menu/LiveNotification";
import ModalChatName from "../../service/chat/ModalChatName";
import ModalAskPassword from "../../service/live-menu/ModalAskPassword";
import AskLoginDialog from "../../service/auth/AskLoginDialog";
import IoTCore from "../../service/iot/IoTClient";
import { userState, channelInfo, androidWebAppInterface, emptySetting } from "../../interface/MainInterface";
import { HttpClientApi, CBroadParams, PutMetadataParams } from "../../service/client-rest-api";
import { ClientIVSPlayer, player as livePlayer } from "../../service/ivs-player/ClientIVSPlayer";
import { VodIVSPlayer, player as vodPlayer } from "../../service/ivs-player/VodIVSPlayer";
import { VodIVSPlayerHigh, player as vodPlayerHigh } from "../../service/ivs-player/VodIVSPlayerHigh";

import { appConfig } from "../../config/Config";
import { CommonUtils } from "../../service/common_utils";

interface propsType {
  webViewStatus: string;
  userState: userState;
  type: string;
}

let channelParams: channelInfo = {
  broad_seq: "",
  broad_title: "",
  broad_desc: "",
  broad_status: "",
  broad_start_tm: "",
  broad_stop_tm: "",
  broad_notice: "",
  broad_prod_list: [],
  broad_cover_url: "",
  vod_url: "",
  vod_started_at: "",
  vod_start_sec: 0,
  vod_end_sec: 0,
  most_concurrent_view_timestamp: "",
  ivs_channel_arn: "",
  ivs_stream_key: "",
  ivs_ingest_endpoint: "",
  ivs_playback_url: "",
  chime_channel_arn: "",
  chime_admin_arn: "",
  total_likes: 0,
  total_views: 0,
  password: "",
  extra_type: "",
  progress_store: "",
  host_id: "",
  host_name: "",
  host_picture: "",
  channel_img: "",
  channel_img_url: "",
  channel_template: false,
  setting: emptySetting,
};

declare var android: androidWebAppInterface;

let callLogin = false;

const LiveMain = (props: propsType) => {
  let navigate = useNavigate();
  const { broadseq } = useParams();
  const [cUtils] = useState(() => new CommonUtils());
  const clientApi = new HttpClientApi(); // API Class

  const [endLoadInfo, setEndLoadInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Constrol Loading Circle
  // const [callLogin, setCallLogin] = useState(false); // 로그인창 호출용
  const [askLogin, setAskLogin] = useState(false); // 로그인 여부 모달창 호출용
  const [chatNameInputModalCommand, setChatNameInputModalCommand] = useState("INIT"); // 채팅 닉네임 입력용 모달창 호출

  const videoRef: any = createRef<HTMLVideoElement>(); // video element ref
  const [videoStatus, setVideoStatus] = useState(""); // 영상의 상태를 해더나 푸터등에 전달하기 위한 객체
  const [hiddenMenu, setHiddenMenu] = useState(false); // Body Click시 메뉴 hidden
  const [pipMode, setPipMode] = useState(false); // pip 진입여부 확인용

  // For Meta Data Control
  const [metaType, setMetaType] = useState("chime"); //채팅을 chime으로 할지 ivs로 할지... 성능상 chime만 사용함...
  const [playerID, setPlayerID] = useState(""); // 로그인 사용자 ID 매핑
  const [likeCount, setLikeCount] = useState(0); // 좋아요 숫자
  const [newLikeCount, setNewLikeCount] = useState(0); // 나 이외의 다른 사용자가 누른 좋아요 숫자
  const [totalViewCount, setTotalViewCount] = useState(0); // 누적 시청자 수

  const [chatUrl, setChatUrl] = useState(""); // 채팅 초기화 용 WebSocket 주소
  const [hiddenChat, setHiddenChat] = useState(false); // 채팅창 동적 hidden용
  const [chatOpen, setChatOpen] = useState(false); // 채팅 메세지 입력창 열렸는지? 열렸으면 나머지 메뉴는 hidden처리
  const [chatInitEnd, setChatInitEnd] = useState(false); // 채팅 모듈 초기화 여부, 초기화 완료시 오른쪽 푸터의 채팅 입력 아이콘 보여주기
  const [screenMode, setScreenMode] = useState("W");

  const [info, setInfo] = useState(channelParams); // 채널정보
  const [streamState, setStreamState] = useState(""); // 스트림 상태, 스트림 상태에 따라서 이벤트를 핸들링할 경우 사용
  const [videoPosition, setVideoPosition] = useState(""); // 비디오 Timeout(현재 상영중인 위치 sec, VOD에서 시간에 맞춰 채팅 보여주기 위해 사용)

  const [liveAnnounceList, setLiveAnnounceList] = useState([] as any); // 라이브 메세지 전송용
  const [liveNotificationOpen, setLiveNotificationOpen] = React.useState(false); // 라이브 메세지 리스트 Drawer

  const [broadQnaList, setBroadQnaList] = useState([] as any); // 방송 QNA LIST

  const [askPwdModalCommand, setAskPwdModalCommand] = useState("INIT"); // Private 방송용 패스워드 모달창 호출
  const [passwordConfirm, setPasswordConfirm] = useState(false); // 패스워드 입력 여부(패스워드 입력완료시 Private 방송입장)

  const [fitMode, setFitMode] = useState(false); // true : contain, false : cover

  const [vodType, setVodType] = useState("full");
  const [haveHighlight, setHaveHighlight] = useState(false);
  const [vodStartSecFull, setVodStartSecFull] = useState(0);
  const [vodEndSecFull, setVodEndSecFull] = useState(0);
  const [vodStartSecHigh, setVodStartSecHigh] = useState(-1);
  const [vodEndSecHigh, setVodEndSecHigh] = useState(-1);

  const iotRef: any = useRef();
  const liveChatRef: any = useRef();
  const liveNoticeRef: any = useRef();

  const handleFitscreen = () => {
    setFitMode(!fitMode);
  };

  // 페이지 최초 로딩시
  useEffect(() => {
    document.body.style.overscrollBehaviorY = "none";
    setIsLoading(true);
    setPageInit(); // 채널정보 로딩 및 채팅 접속
    handleResize(); // 브라우저 크기에 따라서 폰트크기 변경(더현대 Private용)
  }, []);

  const setPageInit = async () => {
    loadChannelInfoFast(); // 방송정보 조회
    procUserJoin(); // 채팅방 접속
  };

  // 방송정보 조회
  const loadChannelInfoFast = async () => {
    if (broadseq !== undefined) {
      const param: CBroadParams = {
        broad_seq: broadseq,
      };

      const res = await clientApi.get_channel_info(param);
      if (res.result_body.password !== "" && !passwordConfirm) {
        // Private 방송의 경우 최초 패스워드 입력
        setAskPwdModalCommand("OPEN_MODAL");
      }
      if (
        res.result_body.most_concurrent_view_timestamp !== "" &&
        res.result_body.vod_started_at !== "" &&
        res.result_body.most_concurrent_view_timestamp > res.result_body.vod_started_at &&
        res.result_body.broad_status === "VOD"
      ) {
        // console.log("here");
        // console.log("most_concurrent_view_timestamp : ", res.result_body.most_concurrent_view_timestamp);
        // console.log("vod_started_at : ", res.result_body.vod_started_at);
        const date1 = dayjs(res.result_body.most_concurrent_view_timestamp, "YYYYMMDDHHmmss");
        const date2 = dayjs(res.result_body.vod_started_at, "YYYYMMDDHHmmss");
        const diff = date1.diff(date2, "s");
        const enddate = date1.add(150, "s").format("YYYYMMDDHHmmss");
        // console.log("enddate : ", enddate);
        const endSec = dayjs(enddate, "YYYYMMDDHHmmss").diff(date2, "s");
        // console.log("endSec : ", endSec);
        // console.log("diff secs : ", diff);
        if (diff > 0) {
          setVodStartSecHigh(diff);
          setVodEndSecHigh(endSec);
          // res.result_body.vod_start_sec = diff;
          // res.result_body.vod_end_sec = endSec;
        }
        setVodType("highlight");
        setHaveHighlight(true);
      }
      setVodStartSecFull(res.result_body.vod_start_sec);
      setVodEndSecFull(res.result_body.vod_end_sec);
      setInfo(res.result_body);
      setBroadStatus(res.result_body.broad_status);
      setTotalViewCount(res.result_body.total_views + 1); // 속도를 위해서 방송입장시 누적 시청자 Count를 +1, 실제 DDB에는 미반영
      setLikeCount(res.result_body.total_likes); // 현재까지의 좋아요 숫자 초기 세팅
      setIsLoading(false); // 로딩서클 초기화
      getBroadQnaList(broadseq); // 방송 Q&A 리스트 불러오기
      setEndLoadInfo(true);
    } else {
      console.error("The channel is undefined");
      setIsLoading(false);
    }
  };

  const loadChannelInfoOnly = async () => {
    if (broadseq !== undefined) {
      const param: CBroadParams = {
        broad_seq: broadseq,
      };

      const res = await clientApi.get_channel_info(param);

      setInfo(res.result_body);
    } else {
      console.error("The channel is undefined");
    }
  };

  // Private 패스워드 입력 완료시
  const askPwdModalProcDone = (res: any) => {
    if (res === "SUCCESS") {
      setPasswordConfirm(true);
    } else if (res === "CANCEL") {
      setPasswordConfirm(false);
      props.webViewStatus === "android" ? android.close() : webClose();
    }
  };

  const webClose = () => {
    if (window.history.length > 0) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  // 채팅방 접속
  const procUserJoin = () => {
    if (props.userState.isAuth) {
      // 로그인한 사용자의 경우
      setPlayerID(props.userState.id);
      enter_chat_channel(props.userState.id, props.userState.nickName);
    } else {
      // 비회원은 임시 ID로 접속(채팅 입력시 로그인 필요)
      const user_id = uuidv4();
      setPlayerID(user_id);
      enter_chat_channel(user_id, "anonymous");
    }
  };

  // 채팅 서비스 접속
  const enter_chat_channel = async (user_id: string, nick_name: string) => {
    if (broadseq !== undefined) {
      const param: CBroadParams = {
        broad_seq: broadseq,
      };

      const enter_res = await clientApi.enter_chat_channel(param, props.userState.isAuth, user_id, nick_name);
      if (enter_res.result_code === "200") setChatUrl(enter_res.result_body.presigned_url); // Set WebSocket URL
    } else {
      console.error("The channel is undefined");
    }
  };

  // 방송 Q&A List 조회
  const getBroadQnaList = async (broad_seq: string) => {
    const broadQnaResult = await clientApi.list_broad_qna(broad_seq, "DESCEND");
    setBroadQnaList(broadQnaResult.result_body);
  };

  // [이벤트] 플레이어 클릭시 메뉴 히든처리
  const fncBodyClick = (e: any) => {
    // console.log("e : ", e);
    if (
      e.target === document.getElementById("tab-screen-content") ||
      e.target === document.getElementsByClassName("chat-list")[0] ||
      e.target === document.getElementsByClassName("live-content-chat")[0]
    ) {
      if (pipMode) return; // PIP모드일때는 동작 안하게
      setHiddenMenu((hiddenMenu) => !hiddenMenu);
      setChatOpen(false);
    }
    setPipMode(false);
  };

  // [이벤트] 채팅창 입력버튼 클릭시
  const fncBtnChatClick = async (status: boolean) => {
    if (props.userState.isAuth) {
      // 로그인한 경우
      if (props.userState.nickName === "") {
        // 닉네임이 없으면 닉네임 입력 폼 활성화
        setChatNameInputModalCommand("OPEN_CHAT_NAME_MODAL");
        setTimeout(() => {
          setChatNameInputModalCommand("CLEAR_COMMAND"); // useState를 사용하기 때문에 Command 초기화 필요
        }, 100);
        return;
      } else {
        // 채팅 입력폼 활성화 시 나머지 메뉴는 안보이게 처리
        setHiddenMenu(true);
        setChatOpen(status);
      }
    } else {
      // 비회원
      setAskLogin(true); // 비회원 채팅 시 로그인 유도시
    }
  };

  // 로그인 요청 결과
  const askLoginDone = (res: any) => {
    res === "YES" ? goLoginPage() : setAskLogin(false);
  };

  // 로그인 페이지 호출
  const goLoginPage = () => {
    setAskLogin(false);
    callLogin = true;
  };

  // 로그인 완료
  const loginSuccess = () => {
    window.location.reload();
  };

  // 닉네임 저장 완료
  const chatNameModalProcDone = (res: any) => {
    if (res === "SUCCESS") window.location.reload();
  };

  // 관리용 메세지 전송 (좋아요, 누적시청자 등)
  const putMetaFunc = async (metaName: string) => {
    const payload = {
      prot: metaName,
      playerID: playerID,
    };

    const param: PutMetadataParams = {
      broad_seq: info.broad_seq,
      chime_channel_arn: info.chime_channel_arn,
      sender_user_arn: info.chime_admin_arn,
      metadata: JSON.stringify(payload),
      meta_type: metaType,
    };
    try {
      const res = await clientApi.put_metadata(param);
      const topic = `live/${broadseq}/metadata`;
      iotRef.current.sendIoTMessageMeta(topic, metaName);
    } catch (e) {
      console.error("[ERROR] putMetaFunc : ", e);
    }
  };

  // [콜백] 비디오 상태 변경시 전달 받음
  const videoStatusCallback = (status: string) => {
    setVideoStatus(status);
    if (status === "onStatePlaying") ivsInitFinished(); // 방송이 시작되면 IVS 초기화 완료
  };

  // [콜백] 비디오 재생 위치(Sec) 콜백
  const liveVideoPositionCallback = (event: string, pos: number) => {
    // 무한 루프 성격으로 사용안함 IoT로 개선
    // setVideoPosition(String(Math.floor(pos))); // 채팅 모듈에 현재 비디오 재생시간 전달
  };

  const videoPositionCallback = (event: string, pos: number) => {
    // console.log("===============================");
    // console.log("vod_started_at : ", info.vod_started_at);
    // console.log("vod_started_at : ", dayjs(info.vod_started_at).format("YYYYMMDDHHmmss"));
    // console.log("most_concurren : ", info.most_concurrent_view_timestamp);
    // console.log("===============================");
    const videoPos = dayjs(info.vod_started_at).add(pos, "s").format("YYYYMMDDHHmmss"); // Sec to datetime
    setVideoPosition(videoPos); // 채팅 모듈에 현재 비디오 재생시간 전달
  };

  // [콜백] 채팅 서비스 초기화 완료시
  const chatInitCallback = (result: boolean) => {
    setChatInitEnd(true); // 우측 Footer에 채팅 초기화 완료 메세지 전달 -> 채팅 입력 아이콘 hidden -> visible
  };

  let isFirst = true;
  // IVS 초기화 완료
  const ivsInitFinished = () => {
    if (isFirst) {
      putMetaFunc("view"); // 시청자 View + 1
      isFirst = false;
    }
  };

  // [콜백] 좋아요 버튼 클릭시
  const btnLikeClickCallback = () => {
    // 본인이 좋아요 누른 경우
    putMetaFunc("like"); // 좋아요 +1 -> DDB
  };

  // [콜백] 좋아요 수신시
  const likeCountCallback = () => {
    setLikeCount((likeCount: number) => likeCount + 1);
  };

  // [콜백] 다른사람 좋아요 수신시
  const newLikeUpCallback = () => {
    setNewLikeCount((newLikeCount: number) => newLikeCount + 1);
  };

  // [콜백] 다른사람 누적 시청자 수신시
  const viewCountCallback = () => {
    // 새로운 시청자 인입시 IVS or Chime에서 이벤트를 수신 받기위한 콜백
    setTotalViewCount((totalViewCount: number) => totalViewCount + 1);
  };

  // [콜백] 스트림 상태 - 현재는 특별한 기능 구현 안함.
  const streamEventCallback = (event_name: string) => {
    setStreamState(event_name);
  };

  // [콜백] 라이브 공지사항 클릭시 과거 이력 보여주기
  const liveAnnounceCallback = (liveAnnounceMessages: any) => {
    // 시간 역순 정렬
    liveAnnounceMessages.sort((a: any, b: any) => {
      if (a.CreatedTimestamp > b.CreatedTimestamp) return -1;
      if (a.CreatedTimestamp < b.CreatedTimestamp) return 1;
      return 0;
    });

    setLiveAnnounceList(liveAnnounceMessages);
    setLiveNotificationOpen(true);
  };

  // 라이브 공지사항 닫기
  const liveNotificationClose = () => {
    setLiveNotificationOpen(false);
  };

  // 방송 Q&A List 열기
  const broadQnaOpen = () => {
    if (broadseq !== undefined) {
      getBroadQnaList(broadseq);
    }
  };

  // 브라우저 이벤트
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      // 메모리 누수 방지를 위해 핸들러 클리어
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 브라우저 크긱에 맞춰 채팅창 높이를 맞춤
  const handleResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width > height) {
      setScreenMode((screenMode) => "W");
    } else {
      setScreenMode((screenMode) => "H");
    }
  };

  // [콜백] PIP 모드에 따라서 메뉴 변경
  const pipCallback = (pip: boolean) => {
    // pip ? window.moveTo(-2000, -2000) : window.moveTo(0, 0);
    setPipMode((pipMode) => pip);
    setHiddenMenu((hiddenMenu) => pip);
    setHiddenChat((hiddenChat) => pip);
  };

  // 안드로이드 WebView에서 이벤트 전달시 리스너
  const eventFromAndroid = async (event: any) => {
    if (event.detail.data === "PipOut") {
      // PIP모드 종료 시 이벤트
      pipCallback(false);
    }
  };
  window.addEventListener("eventToAndroidWebView", eventFromAndroid);

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${broadseq}/metadata`) {
      // 구독중인 라이브
      if (payLoad.message === "view" && payLoad.senderId !== clientId) viewCountCallback();
      if (payLoad.message === "like") likeCountCallback();
      if (payLoad.message === "like" && payLoad.senderId !== clientId) newLikeUpCallback();
      if (payLoad.message === "start_stream") setStreamStart();
      if (payLoad.message === "ready_stream") setStreamPause();
      if (payLoad.message === "reloadChannelInfo") loadChannelInfoOnly();
    }
  }

  const [broadStatus, setBroadStatus] = useState("");
  const setStreamStart = () => {
    setBroadStatus((broadStatus) => "START");
  };
  const setStreamPause = () => {
    setBroadStatus((broadStatus) => "READY");
  };

  // 채팅창에서 본인 이름 클릭시 닉네임 변경
  const nickNameChange = () => {
    if (props.userState.isAuth) {
      // 로그인한 경우
      // 닉네임이 없으면 닉네임 입력 폼 활성화
      setChatNameInputModalCommand("OPEN_CHAT_NAME_MODAL");
      setTimeout(() => {
        setChatNameInputModalCommand("CLEAR_COMMAND"); // useState를 사용하기 때문에 Command 초기화 필요
      }, 100);
      return;
    } else {
      // 비회원
      setAskLogin(true); // 비회원 채팅 시 로그인 유도시
    }
  };

  // 채팅 메세지 전송
  const sendMessage = (msg: string, meta: string) => {
    liveChatRef.current.sendMessage(msg, meta);
  };

  const toggleVodType = () => {
    if (vodType === "highlight") setVodType((vodType) => "full");
    else setVodType((vodType) => "highlight");
  };

  // 채팅 연결 끊김
  const chatClose = () => {
    if (!callLogin) {
      console.info("chat close >>>> Re Connect Chat Room");
      // window.location.reload(); // 채팅방 입장
    }
  };

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    if (!callLogin) {
      window.location.reload(); // IoT다시 맺기
    }
  };

  const [videoMode, setVideoMode] = useState(1);
  useEffect(() => {
    if (videoStatus === "onStatePlaying") {
      let player = livePlayer;
      if (info.broad_status === "VOD" && vodType === "full") player = vodPlayer;
      if (info.broad_status === "VOD" && vodType === "highlight") player = vodPlayerHigh;

      const videoQlty = player.getQuality();
      if (!cUtils.isEmptyObj(videoQlty)) {
        const videoHeight = videoQlty.height;
        const videoWidth = videoQlty.width;
        console.log("videoQlty : ", videoHeight, "/", videoWidth);
        if (videoHeight > videoWidth) setVideoMode(1);
        else setVideoMode(0);
      }
    }
  }, [videoStatus]);

  return (
    <>
      {callLogin ? ( // 로그인 창 호출
        <Login userState={props.userState} callBack={loginSuccess} />
      ) : (
        <div className="viewer-root">
          {!passwordConfirm && info.password !== "" && info.password !== undefined ? ( // Private 패스워드 입력창
            <div className="client-video no-scroll">
              <ModalAskPassword
                modalCommand={askPwdModalCommand}
                password={info.password}
                callBack={askPwdModalProcDone}
              />
            </div>
          ) : (
            <div className={videoMode === 1 ? "Live-Layout-wrapper small-layout" : "Live-Layout-wrapper"}>
              <div className={videoMode === 1 ? "Live-Layout-inner small-layout-inner" : "Live-Layout-inner"}>
                <div className="Live-Swipe-content">
                  <div className="VideoPlayer-wrapper color-snow">
                    {playerID !== "" && info.broad_status !== "VOD" && (
                      <ClientIVSPlayer
                        type={props.type}
                        endLoadInfo={endLoadInfo}
                        broad_seq={info.broad_seq}
                        broad_status={broadStatus}
                        playbackUrl={info.ivs_playback_url}
                        video_ref={videoRef}
                        player_id={playerID}
                        streamState={streamState}
                        like_count_callback={likeCountCallback}
                        newLikeUpCallback={newLikeUpCallback}
                        viewCountCallback={viewCountCallback}
                        videoStatusCallback={videoStatusCallback}
                        videoPositionCallback={liveVideoPositionCallback}
                        pip_callback={pipCallback}
                        setting={info.setting}
                        fitMode={fitMode}
                      />
                    )}
                    {info.broad_status === "VOD" ? (
                      vodType === "highlight" ? (
                        <VodIVSPlayerHigh
                          broad_seq={info.broad_seq}
                          playbackUrl={info.vod_url}
                          video_ref={videoRef}
                          vod_start_sec={vodStartSecHigh}
                          vod_end_sec={vodEndSecHigh}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          pip_mode={pipMode}
                          pip_callback={pipCallback}
                          hidden_menu={hiddenMenu}
                          fitMode={fitMode}
                        />
                      ) : (
                        <VodIVSPlayer
                          broad_seq={info.broad_seq}
                          playbackUrl={info.vod_url}
                          video_ref={videoRef}
                          vod_start_sec={vodStartSecFull}
                          vod_end_sec={vodEndSecFull}
                          videoStatusCallback={videoStatusCallback}
                          videoPositionCallback={videoPositionCallback}
                          pip_mode={pipMode}
                          pip_callback={pipCallback}
                          hidden_menu={hiddenMenu}
                          fitMode={fitMode}
                        />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={
                      hiddenMenu && info.broad_status === "VOD"
                        ? "Tap-screen-wrapper color-snow bottom-50"
                        : "Tap-screen-wrapper color-snow"
                    }
                  >
                    <div id="tab-screen-content" className="Tap-screen-content" onClick={fncBodyClick}>
                      <div className="live-header-root">
                        <LiveHeader
                          broad_seq={info.broad_seq}
                          broad_title={info.broad_title}
                          setting={info.setting}
                          hidden_menu={hiddenMenu}
                          pip_mode={pipMode}
                          webViewStatus={props.webViewStatus}
                          video_ref={videoRef}
                          video_status={videoStatus}
                          playbackUrl={info.ivs_playback_url}
                          extra_type={info.extra_type}
                          host_id={info.host_id}
                          host_name={info.host_name}
                          host_picture={info.host_picture}
                          like_count={likeCount}
                          tot_view_count={totalViewCount}
                          broad_status={info.broad_status}
                          pip_callback={pipCallback}
                          screenMode={screenMode}
                          vodType={vodType}
                          haveHighlight={haveHighlight}
                          toggleVodType={toggleVodType}
                          handleFitscreen={handleFitscreen}
                        />
                      </div>
                      <div className="live-content-root">
                        <div className="live-content-wrapper">
                          <div className="live-content-chat">
                            {playerID !== "" &&
                              chatUrl !== "" &&
                              info.broad_status !== "VOD" &&
                              info.extra_type !== "P001" && (
                                <ChatVerNoSdk
                                  ref={liveChatRef}
                                  broad_seq={info.broad_seq}
                                  channelArn={info.chime_channel_arn}
                                  adminArn={info.chime_admin_arn}
                                  chatUrl={chatUrl}
                                  screenMode={screenMode}
                                  chat_open={chatOpen}
                                  hidden_menu={hiddenMenu}
                                  player_id={playerID}
                                  chatInitCallback={chatInitCallback}
                                  like_count_callback={likeCountCallback}
                                  newLikeUpCallback={newLikeUpCallback}
                                  viewCountCallback={viewCountCallback}
                                  streamEventCallback={streamEventCallback}
                                  liveAnnounceCallback={liveAnnounceCallback}
                                  videoPosition={videoPosition}
                                  userState={props.userState}
                                  setting={info.setting}
                                  nickNameChange={nickNameChange}
                                  chat_close={chatClose}
                                />
                              )}
                            {playerID !== "" &&
                              chatUrl !== "" &&
                              info.broad_status !== "VOD" &&
                              info.extra_type === "P001" && (
                                <ChatVip
                                  ref={liveChatRef}
                                  broad_seq={info.broad_seq}
                                  channelArn={info.chime_channel_arn}
                                  adminArn={info.chime_admin_arn}
                                  chatUrl={chatUrl}
                                  screenMode={screenMode}
                                  chat_open={chatOpen}
                                  hidden_menu={hiddenMenu}
                                  player_id={playerID}
                                  chatInitCallback={chatInitCallback}
                                  like_count_callback={likeCountCallback}
                                  newLikeUpCallback={newLikeUpCallback}
                                  viewCountCallback={viewCountCallback}
                                  streamEventCallback={streamEventCallback}
                                  liveAnnounceCallback={liveAnnounceCallback}
                                  videoPosition={videoPosition}
                                  userState={props.userState}
                                  setting={info.setting}
                                  nickNameChange={nickNameChange}
                                  chat_close={chatClose}
                                />
                              )}
                            {playerID !== "" && chatUrl !== "" && info.broad_status === "VOD" && (
                              <ChatVerVod
                                broad_seq={info.broad_seq}
                                channelArn={info.chime_channel_arn}
                                adminArn={info.chime_admin_arn}
                                chatUrl={chatUrl}
                                chat_open={chatOpen}
                                screenMode={screenMode}
                                hidden_menu={hiddenMenu}
                                chatInitCallback={chatInitCallback}
                                player_id={playerID}
                                like_count_callback={likeCountCallback}
                                newLikeUpCallback={newLikeUpCallback}
                                viewCountCallback={viewCountCallback}
                                streamEventCallback={streamEventCallback}
                                videoPosition={videoPosition}
                                vod_started_at={info.vod_started_at}
                                userState={props.userState}
                                setting={info.setting}
                              />
                            )}
                          </div>
                          {hiddenMenu ? (
                            <></>
                          ) : info.broad_seq !== "" && info.extra_type !== "P001" ? (
                            <div
                              className={
                                info.broad_status === "VOD" ? "live-content-notice mb-17" : "live-content-notice"
                              }
                            >
                              <LiveNotice ref={liveNoticeRef} broad_info={info} />
                            </div>
                          ) : (
                            <></>
                          )}
                          {info.broad_status !== "VOD" ? (
                            <div className="live-content-footer">
                              <LiveFooter
                                broad_seq={info.broad_seq}
                                user_id={props.userState.id}
                                userState={props.userState}
                                chat_open={chatOpen}
                                hidden_menu={hiddenMenu}
                                sendMessage={sendMessage}
                              />
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="live-content-footer-right">
                            <LiveRight
                              like_count={likeCount}
                              broad_seq={info.broad_seq}
                              extra_type={info.extra_type}
                              hidden_menu={hiddenMenu}
                              webViewStatus={props.webViewStatus}
                              chat_click={fncBtnChatClick}
                              chat_open={chatOpen}
                              chat_btn_ready={chatInitEnd}
                              like_click={btnLikeClickCallback}
                              new_like_count={newLikeCount}
                              broad_prod_list={info.broad_prod_list}
                              broad_status={info.broad_status}
                              broadQnaList={broadQnaList}
                              broadQnaOpen={broadQnaOpen}
                              pip_callback={pipCallback}
                              setting={info.setting}
                              screenMode={screenMode}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <AskLoginDialog command={askLogin} callBack={askLoginDone} />
          <ModalChatName modalCommand={chatNameInputModalCommand} callBack={chatNameModalProcDone} />
          {broadseq !== undefined && (
            <IoTCore
              ref={iotRef}
              broadSeq={broadseq}
              iotRegion={appConfig.iotRegion}
              iotIdentityPollId={appConfig.iotIdentityPollId}
              iotEndPoint={appConfig.iotEndPoint}
              recieveIoTMessage={recieveIoTMessage}
              iotLost={iotLost}
            />
          )}
        </div>
      )}
    </>
  );
};

export default LiveMain;
