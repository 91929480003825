import { useEffect, useState } from "react";
import dayjs from "dayjs";

// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
// Interface
import { HttpClientApi } from "../../../service/client-rest-api";

// Local CSS
import "./LiveQnA.css";

interface propsType {
  newQnAMsg: any;
  broad_seq: any;
  iconSize: string;
  hidden_menu: boolean;
  isNotFloat: any;
  isFloat: any;
  videoMode: number;
}

const LiveQnA = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class

  const [broadQnaList, setBroadQnaList] = useState<any>([]); // 방송 QNA LIST
  const [openQna, setOpenQna] = useState(false); // 공지사항, Q&A 상세보기

  useEffect(() => {
    getBroadQnaList(props.broad_seq);
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBroadQnaList([]);
      setOpenQna(false);
    };
  }, []);

  // 방송 Q&A List 조회
  const getBroadQnaList = async (broad_seq: string) => {
    const broadQnaResult = await clientApi.list_broad_qna(broad_seq, "DESCEND");
    setBroadQnaList(broadQnaResult.result_body);
  };

  useEffect(() => {
    if (props.newQnAMsg !== null) {
      setBroadQnaList([props.newQnAMsg, ...broadQnaList]);
    }
  }, [props.newQnAMsg]);

  // 상세보기 Drawer Control
  const handleQnaDrawerClose = () => {
    setOpenQna(false);
    props.isFloat();
  };
  const handleQnaDrawerOpen = () => {
    getBroadQnaList(props.broad_seq);
    props.isNotFloat();

    setTimeout(() => {
      setOpenQna(true);
    }, 200);
  };

  // 마스킹 처리
  function maskingChar(strChar: string) {
    if (strChar === undefined || strChar === "") {
      return "";
    }
    return strChar.substring(0, 3) + "".padEnd(strChar.substring(3, strChar.length).length, "*");
  }

  return (
    <>
      <div>
        <Box sx={props.hidden_menu ? { display: "none" } : { display: "block" }} onClick={handleQnaDrawerOpen}>
          <img className="thd-footer-right-icon" src="/images/btn-qna.png" />
        </Box>
        {/* 상세보기 */}
        <Drawer
          PaperProps={
            props.videoMode === 1
              ? {
                  elevation: 0,
                  style: {
                    borderTopRightRadius: "16px",
                    borderTopLeftRadius: "16px",
                  },
                  sx: {
                    ...{
                      width: "100%",
                      maxWidth: "56.25vh",
                      left: "50%",
                      transform: "translateX(calc(-50% + 0.5px)) !important",
                      overflow: "hidden",
                    },
                  },
                }
              : { elevation: 0, style: { borderTopRightRadius: "16px", borderTopLeftRadius: "16px" } }
          }
          anchor="bottom"
          open={openQna}
          onClose={handleQnaDrawerClose}
          className="thd-live-more-body"
        >
          <Box
            sx={{
              width: "100%",
              height: "60vh",
              bgcolor: "while",
              borderRadius: "16px",
              position: "relative",
              padding: "18px",
              overflow: "hidden",
            }}
          >
            <Box className="thd-live-qna-drawer-top">
              <Box component="img" src="/images/modal-qna.png" className="thd-live-qna-drawer-top-img" />
              <div className="thd-live-qna-drawer-top-text">Q&A</div>
              <Box className="thd-live-qna-drawer-close">
                <Box component="img" src="/images/notice-close.svg" onClick={handleQnaDrawerClose} />
              </Box>
            </Box>
            {/* QNA 상세보기 */}
            <Box className="thd-live-qna-more" sx={{ display: "flex", width: "100%" }}>
              <List className="thd-live-qna-list" sx={{ paddingTop: "0px" }}>
                {broadQnaList.map((obj: any, index: number) => {
                  if (obj.sk !== undefined) {
                    return (
                      <div key={`qna${obj.sk}`}>
                        <ListItem disablePadding sx={{ alignItems: "start", marginTop: "10px" }}>
                          {/* Question Icon */}
                          <Box component="img" className="thd-question-icon" src="/images/icon-question.png" />
                          <ListItemText
                            className="thd-question-item"
                            primary={
                              <Typography
                                paddingRight="10px"
                                component="div"
                                fontFamily="Noto Sans KR"
                                fontWeight="700"
                                fontSize="16px"
                                fontStyle="normal"
                                color="#111111"
                              >
                                {obj.qContent}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                component="div"
                                fontFamily="Noto Sans KR"
                                fontWeight="400"
                                fontSize="13px"
                                fontStyle="normal"
                                color="#999999"
                              >
                                {maskingChar(obj.qUserName) +
                                  " • " +
                                  dayjs(String(obj.qCreatedTimestamp)).format("YYYY.MM.DD, HH:mm")}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem disablePadding className="thd-answer-list-item">
                          <ListItemText
                            primary={
                              <Typography
                                component="div"
                                fontFamily="Noto Sans KR"
                                fontWeight="400"
                                fontSize="14px"
                                fontStyle="normal"
                                color="#111111"
                              >
                                {obj.aContent}
                              </Typography>
                            }
                            // secondary={
                            //   <Typography component="div" fontSize="12px" className="answer-time">
                            //     {obj.aUserName +
                            //       " • " +
                            //       dayjs(String(obj.aCreatedTimestamp)).format("YYYY.MM.DD, HH:mm")}
                            //   </Typography>
                            // }
                          />
                        </ListItem>
                      </div>
                    );
                  }
                })}
              </List>
            </Box>
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default LiveQnA;
