import React, { useEffect, useState, useRef } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";
import SendIcon from "@mui/icons-material/Send";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AddCommentIcon from "@mui/icons-material/AddComment";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import CampaignIcon from "@mui/icons-material/Campaign";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BlockIcon from "@mui/icons-material/Block";
import AutoSizer from "react-virtualized-auto-sizer";
import { HttpChannelApi } from "../../service/channel-api";
import {
  HttpClientApi,
  ListChannelMessageParams,
  SendChannelMessageRawParams,
  PutMetadataParams,
} from "../../service/client-rest-api";
import ModalChatName from "../../service/chat/ModalChatName";
import * as ChatConstant from "./ChatConstant";
import { appConfig } from "../../config/Config";
import { userState } from "../../interface/MainInterface";
import { CommonUtils } from "../../service/common_utils";
import LoadingCircle from "../../utilities/LoadingCircle";
import ChatBlock from "./ChatBlock";
import "./Chat.css";

interface propsType {
  broadInfo: any;
  broadSeq: string;
  chatUrl: string;
  channelArn: string;
  adminArn: string;
  chat_callback: any;
  streamEventCallback: any;
  userState: userState;
  tabValue: string;
  disconnectChat: any;
  sendIoTMessage: any;
}

interface chatSendForm {
  msg: string;
}

const Persistence = {
  PERSISTENT: "PERSISTENT",
  NON_PERSISTENT: "NON_PERSISTENT",
};

const MessageType = {
  STANDARD: "STANDARD",
  CONTROL: "CONTROL",
};

const ChatPlaceholer = {
  NORMAL: "메세지를 입력하세요.",
  MANAGER_CAMPAIGN: "라이브 공지사항을 입력하세요.",
};

const ChatAdmin = (props: propsType) => {
  const ChannelApi = new HttpChannelApi();
  const ClientApi = new HttpClientApi();
  const chatBlockRef: any = useRef();

  const cUtils = new CommonUtils();

  const [isLoading, setIsLoading] = useState(false);
  const [channelArn, setChannelArn] = useState<string>(""); // Member Variable
  const [adminArn, setAdminArn] = useState<string>("");
  const [broadSeq, setBroadSeq] = useState<string>("");
  // Chime Messages
  const [messages, setMessages] = useState<any>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({ display: "none" });

  const [chatNameInputModalCommand, setChatNameInputModalCommand] = useState("INIT"); // 채팅 닉네임 입력용 모달창 호출

  let inputBoxCss = {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  };
  const [inputBoxClassName, setInputBoxClassName] = useState(inputBoxCss);

  let quoteBoxCss = {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  };
  const [quoteBoxClassName, setQuoteBoxClassName] = useState(quoteBoxCss);
  const [quoteText, setQuoteText] = useState("");
  const [qMessageId, setQMessageId] = useState("");

  const [liveAnnounce, setLiveAnnounce] = useState(false);
  const [liveAnnounceText, setLiveAnnounceText] = useState("");
  const [mngrCampAlertStyle, setMngrCampAlertStyle] = useState({ display: "none" });
  const [metaType, setMetaType] = React.useState("chime");
  const inputMsgRef = useRef<any>();
  // Ref for subscribe callback
  const messagesRef = useRef(messages);
  const channelArnRef = useRef(channelArn);
  const adminArnRef = useRef(adminArn);
  const broadSeqRef = useRef(broadSeq);
  // Ref for chatting list
  const listRef = useRef<any>(null);
  // Array for set dynamin height foreach message contents
  const rowHeights = useRef<any>({});

  let chatCss = { width: "100%", height: 400 };
  const [mainDivCss, setMainDivCss] = useState(chatCss);

  // Form for send message to chime channel
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  // Chime Connection
  useEffect(() => {
    if (props.channelArn !== "" && props.chatUrl !== "") {
      if (!props.userState.isAuth) return;
      setChannelArn(props.channelArn);
      setAdminArn(props.adminArn);
      setBroadSeq(props.broadSeq);

      // 소켓 시작
      const connectionInit = new WebSocket(props.chatUrl);
      connectionInit.onopen = (event) => {};

      connectionInit.onclose = (event) => {
        // If the websocket closes, remove the current chat token
        console.error("Disconnected to the chat room.", new Date().toISOString());
        console.error("Disconnected to the chat room.", event);
        props.disconnectChat();
      };

      connectionInit.onerror = (event) => {
        console.error("Chat room websocket error observed:", event);
      };

      connectionInit.onmessage = (event) => {
        messagesProcessor(event);
      };

      return () => {
        // chatService.close();
      };
    }
  }, [props.channelArn, props.adminArn, props.chatUrl, props.userState.isAuth]);

  // subscript callback 연동용 Ref 최신화
  useEffect(() => {
    messagesRef.current = messages;
    channelArnRef.current = channelArn;
    adminArnRef.current = adminArn;
    broadSeqRef.current = broadSeq;
  });

  // 차임 메시지 처리용 프로세서
  const messagesProcessor = async (event: any) => {
    const data = JSON.parse(event.data);
    const eventType = data.Headers["x-amz-chime-event-type"];

    switch (eventType) {
      case "SESSION_ESTABLISHED":
        getListChannelMessages(broadSeqRef.current);
        break;
      case "CREATE_CHANNEL_MESSAGE":
        const msgPayload = JSON.parse(data.Payload);
        if (channelArnRef.current === msgPayload?.ChannelArn) {
          processChannelMessage(msgPayload);
        }
        break;
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE": // message process
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
      case "DELETE_CHANNEL":
      case "CREATE_CHANNEL_MEMBERSHIP":
      case "UPDATE_CHANNEL_MEMBERSHIP":
      case "DELETE_CHANNEL_MEMBERSHIP":
      default:
        console.info("Not covered EventType:" + eventType);
    }
  };

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (broad_seq: string) => {
    const param: ListChannelMessageParams = {
      broad_seq: broad_seq,
      next_token: nextToken,
      list_mode: "total",
    };

    await ClientApi.list_channel_messages(param).then((result: any) => {
      if (result.result_code === "200") {
        setMessages(result.result_body.Messages);
        //   setNextToken(result.result_body.NextToken); // 이거 추가하면 반응형으로 만들 수 있음
        props.chat_callback(true);
      }
    });
  };

  // 차임 메시지 도착(실시간)시 처리용 프로세스
  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    let isDuplicate = false;
    messagesRef.current.forEach((m: any, i: number, self: any) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    // payload의 길이 때문에 단발성(NON_PERSISTENT) 메시지 기능을 관리자 메시지로 사용한다.
    if (!isDuplicate && newMessage.Persistence === ChatConstant.Persistence.NON_PERSISTENT) {
      const metadataText = JSON.parse(newMessage.Content);
      // if (metadataText.prot === "like") like_count_up();
      // if (metadataText.prot === "like" && metadataText.playerID !== props.player_id) new_like_up();
      // if (metadataText.prot === "view" && metadataText.playerID !== props.player_id) view_count_up();
      if (metadataText.prot === "manager_campaign") procLiveAnnounce(metadataText.contents);
      if (metadataText.prot === "stream_state") handleStreamState(metadataText.event_name);
    }

    let newMessages: any = [...messagesRef.current];
    if (!isDuplicate && newMessage.Persistence === ChatConstant.Persistence.PERSISTENT) {
      if (newMessage.Metadata === "" || newMessage.Metadata === undefined) {
        // 일반채팅
        newMessages = [...newMessages, newMessage];
        setMessages(newMessages);
      } else {
        newMessages = [...newMessages, newMessage];
        setMessages(newMessages);
      }
    }
  };

  // 채팅 메시지 전송버튼 클릭
  const onSubmit: SubmitHandler<chatSendForm> = async (data) => {
    document.getElementById("input-Message")?.focus();
    reset({ msg: "" });
    await sendMessage(data.msg, "");
  };

  // 채팅 메시지 전송
  const sendMessage = async (msg: string, meta: string) => {
    try {
      setNeedScrollEnd(true); // 채팅 로딩 후 스크롤 제일 아래로 활성화
      setBtnNewMsgBoxStyle({ display: "none" }); // 세로운 메시지 알림 닫기

      const origin_msg = msg;
      // 답글이 있으면 답글을 신규 컨텐츠에 추가
      if (quoteText !== "") {
        msg = quoteText + appConfig.quote_separator + msg;
      }

      if (liveAnnounce) {
        msg = "📣" + msg;
        meta = "live_announcements";
      }

      const payload: SendChannelMessageRawParams = {
        channel_arn: props.channelArn,
        content: msg,
        type: MessageType.STANDARD,
        persistence: Persistence.PERSISTENT,
        metadata: meta,
        user_arn: appConfig.appInstanceUserArn,
        broad_seq: props.broadSeq,
      };
      console.log("here");
      await ClientApi.send_channel_message_raw(payload).then(async (res) => {
        console.log("res: ", res);
        fncResetQuote();
        setLiveAnnounce(false);
        if (quoteText !== "") {
          const resultQna = await ClientApi.send_qna_message(
            props.broadSeq,
            qMessageId,
            res.result_body.message_id,
            origin_msg,
            props.userState.id
          );
          if (resultQna.result_code === "200") {
            // console.log("resultQna.result_body : ", resultQna.result_body);
            props.sendIoTMessage({
              message: "qna",
              q: resultQna.result_body.q_message_info.ChannelMessage.Content,
              qSender: resultQna.result_body.q_message_info.ChannelMessage.Sender.Name,
              a: origin_msg,
              aSender: resultQna.result_body.a_message_info.ChannelMessage.Sender.Name,
            });
          }
        }
      });
    } catch (e) {
      console.error("send msg ERROR : ", e);
    }
  };

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (props.tabValue === "chat" && listRef.current !== null) listRef?.current.scrollToItem(messages.length);
  };

  useEffect(() => {
    if (messages.length > 0 && props.chatUrl !== "" && props.tabValue === "chat") {
      if (needScrollEnd) {
        scrollToBottom();
      } else {
        setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, props.tabValue]);

  async function putMetaFunc(metaName: string, content: string) {
    setIsLoading(true);
    const payload = {
      prot: metaName,
      playerID: props.adminArn,
      contents: content,
    };

    const param: PutMetadataParams = {
      broad_seq: props.broadSeq,
      chime_channel_arn: props.channelArn,
      sender_user_arn: props.adminArn,
      metadata: JSON.stringify(payload),
      meta_type: metaType,
    };
    try {
      await ClientApi.put_metadata(param).then((res) => {
        setLiveAnnounce(false);
        setIsLoading(false);
      });
    } catch (e) {
      console.error("[ERROR] putMetaFunc : ", e);
      setIsLoading(false);
    }
  }

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 56;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow({ index, style }: ListChildComponentProps) {
    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
      // eslint-disable-next-line
    }, [rowRef]);

    return (
      <ListItem style={style} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={{
                  width: "100%",
                  color: "black",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ padding: "10px", borderRadius: "5px" }}>
                  {messages[index].Sender.Arn === props.userState.userArn ? (
                    <span
                      style={{ color: "Pink", whiteSpace: "nowrap" }}
                      onClick={() => {
                        nickNameChange(messages[index].Sender.Arn);
                      }}
                    >
                      {messages[index].Sender.Name}
                    </span>
                  ) : (
                    <span style={{ color: "DarkGray", whiteSpace: "nowrap" }}>{messages[index].Sender.Name}</span>
                  )}
                </div>
                <div style={{ flexGrow: 1, backgroundColor: "lightblue", padding: "10px", borderRadius: "5px" }}>
                  {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                    const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                    let styleCss: any = {
                      color: "black",
                      wordBreak: "break-all",
                    };
                    if (idx !== repleLength - 1) styleCss.color = "gray";
                    if (idx === 0) {
                      if (messages[index].Metadata !== undefined && messages[index].Metadata === "chat_blocked") {
                        styleCss.color = "cadetblue";
                        // 차단된 메세지
                        return (
                          <del key={index} style={styleCss}>
                            {"[차단]"}
                            {line}
                          </del>
                        );
                      } else {
                        return (
                          <span key={index} style={styleCss}>
                            {line}
                          </span>
                        );
                      }
                    } else {
                      return (
                        <span key={index + "-" + idx} style={styleCss}>
                          <br />
                          {appConfig.quote_separator + " " + line}
                        </span>
                      );
                    }
                  })}
                </div>
                <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                  {dayjs(String(messages[index].CreatedTimestamp)).format("HH:mm")}
                </div>
                {messages[index].Metadata !== undefined && messages[index].Metadata === "chat_blocked" ? (
                  <div className="empty-68">{"  "}</div>
                ) : (
                  <>
                    <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                      <IconButton sx={{ padding: 0 }} onClick={() => fncQuote(messages[index])}>
                        <AddCommentIcon />
                      </IconButton>
                    </div>
                    <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                      <IconButton sx={{ padding: 0 }} onClick={() => fncBlock(messages[index])}>
                        <BlockIcon sx={{ color: "red" }} />
                      </IconButton>
                    </div>
                  </>
                )}
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };
  // 인용구에 대해서도 높이 자동 조절
  useEffect(() => {
    if (quoteText === "") setChatListHeight((chatListHeight) => 583);
    else setChatListHeight((chatListHeight) => 523);
  }, [quoteText]);

  const fncQuote = (msg: any) => {
    setLiveAnnounce(false);
    if (props.broadInfo.extra_type === "P001") setQuoteText(`(${msg.Sender.Name}) ${msg.Content}`);
    else setQuoteText(`(${maskingChar(msg.Sender.Name)}) ${msg.Content}`);
    setQMessageId(msg.MessageId);
    inputMsgRef.current.focus();
  };

  function maskingChar(strCar: string) {
    if (strCar === undefined || strCar === "") {
      return "";
    }
    if (appConfig.chat_id_mask)
      return strCar.substring(0, 3) + "".padEnd(strCar.substring(3, strCar.length).length, "*");
    else return strCar;
  }

  const inputMsgKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") fncResetQuote();
  };

  const fncResetQuote = () => {
    setQuoteText((quoteText) => "");
    setQMessageId((qMessageId) => "");
  };

  const handleLiveAnnounce = () => {
    fncResetQuote();
    setLiveAnnounce(!liveAnnounce);
    inputMsgRef.current.focus();
  };

  const procLiveAnnounce = (contents: string) => {
    setLiveAnnounceText(contents);
  };

  useEffect(() => {
    if (liveAnnounceText !== "") {
      setMngrCampAlertStyle({ display: "flex" });
      setTimeout(() => {
        setLiveAnnounceText("");
      }, 2000);
    } else {
      setMngrCampAlertStyle({ display: "none" });
    }
  }, [liveAnnounceText]);

  // EventBridge에서 IVS stream 상태에 따라 이벤트 수신
  const handleStreamState = (event_name: string) => {
    props.streamEventCallback(event_name);
  };

  const resetMessages = async () => {
    if (window.confirm("채팅 메세지를 모두 삭제하시겠습니까?")) {
      setIsLoading(true);
      for (const message of messages) {
        const result = await ChannelApi.delete_channel_message(props.channelArn, message.MessageId, props.adminArn);
      }
      setIsLoading(false);
      window.location.reload();
    }
  };

  const [chatListHeight, setChatListHeight] = useState(583);

  const nickNameChange = (senderArn: string) => {
    if (senderArn !== props.userState.userArn) return;
    // 닉네임이 없으면 닉네임 입력 폼 활성화
    setChatNameInputModalCommand("OPEN_CHAT_NAME_MODAL");
    setTimeout(() => {
      setChatNameInputModalCommand("CLEAR_COMMAND"); // useState를 사용하기 때문에 Command 초기화 필요
    }, 100);
    return;
  };

  // 닉네임 저장 완료
  const chatNameModalProcDone = (res: any) => {
    if (res === "SUCCESS") window.location.reload();
  };

  const [frameSrc, setFrameSrc] = useState("");
  const openLivePreview = (broadSeq: string) => {
    setFrameSrc((frameSrc) => "");
    const linkUrl = `${appConfig.domain}/stream/${broadSeq}?userId=`;
    setFrameSrc((frameSrc) => linkUrl);
  };

  useEffect(() => {
    openLivePreview(props.broadSeq);
  }, []);

  const downloadChatList = () => {
    // sheet header
    let chatHistory: any = [["순번", "채팅일시", "작성자", "내용"]];
    // 각 행 순번 만들기
    let chatIndex = 1;
    // 내용 생성
    for (const message of messages) {
      const detail = [
        chatIndex,
        dayjs(String(message.CreatedTimestamp)).format("YYYY-MM-DD HH:mm:ss"),
        message.Sender.Name,
        message.Content,
      ];
      chatHistory = [...chatHistory, detail];
      chatIndex += 1;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 130 }, { wpx: 800 }];
    const sheetName = "채팅내역";
    // 파일명
    const fileName =
      dayjs(String(props.broadInfo.broad_start_tm)).format("YYYY-MM-DD HHmm") +
      "_채팅_" +
      props.broadInfo.broad_title +
      ".xlsx";
    cUtils.downloadExcel(chatHistory, colWidth, sheetName, fileName);
  };

  const fncBlock = async (message: any) => {
    // Speed Dial open
    chatBlockRef.current.open(message);
  };

  const callbackChatBlock = () => {};

  return (
    <Grid container spacing={2} component="div">
      {props.broadInfo.extra_type === "P001" ? (
        <></>
      ) : (
        <Grid item xs={12} sm={4}>
          <div className="channel-manager-player">
            <iframe id={"ChannelManagerlivePreview"} src={frameSrc} className="channel-manager-player-frame"></iframe>
          </div>
        </Grid>
      )}
      <Grid item xs={12} sm={props.broadInfo.extra_type === "P001" ? 12 : 8}>
        <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", height: 674, p: 2 }} elevation={5}>
            <Box component="div" sx={{ width: "100%", height: chatListHeight }} className="chat-admin">
              <AutoSizer
                style={{
                  height: "100%",
                  width: "100%",
                  border: "1px solid",
                  borderRadius: "5pt",
                  overflow: "hidden",
                }}
              >
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemSize={getRowHeight}
                    itemCount={messages.length}
                    overscanCount={5}
                    className="chat-list-admin"
                    ref={listRef}
                    onItemsRendered={evtItemRendered}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </Box>
            <div id="divQuoteText" hidden={quoteText === ""}>
              <Box className="quote-Container" sx={quoteBoxClassName}>
                <div style={{ flexGrow: 1 }}>{quoteText}</div>
                <IconButton sx={{ padding: 0 }} onClick={fncResetQuote}>
                  <CloseIcon className="color-snow" />
                </IconButton>
              </Box>
            </div>
            <Box
              id="input-Container"
              sx={inputBoxClassName}
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <IconButton
                onClick={resetMessages}
                sx={
                  props.userState.isSuperAdmin || props.userState.userArn === props.adminArn ? {} : { display: "none" }
                }
              >
                <DeleteForeverIcon className="color-orangered" />
              </IconButton>
              <div hidden={quoteText === ""}>
                <Box className="quote-icon">{appConfig.quote_separator}</Box>
              </div>
              <TextField
                id="input-Message"
                placeholder={liveAnnounce ? ChatPlaceholer.MANAGER_CAMPAIGN : ChatPlaceholer.NORMAL}
                sx={{ width: "100%" }}
                type="search"
                autoComplete="off"
                inputProps={{ enterKeyHint: "Send" }}
                inputRef={inputMsgRef}
                onKeyDown={inputMsgKeyPress}
                {...register("msg", { required: true, maxLength: 3000 })}
              />
              <IconButton onClick={handleLiveAnnounce}>
                {liveAnnounce ? <CampaignIcon className="color-orangered" /> : <CampaignIcon />}
              </IconButton>
              <IconButton onClick={downloadChatList}>
                <DownloadIcon />
              </IconButton>
              <IconButton type="submit" aria-label="send">
                <SendIcon />
              </IconButton>
            </Box>
          </Paper>
          <Box className="btn-new-chat" sx={btnNewMsgBoxStyle}>
            <Button variant="contained" size="small" onClick={scrollToBottom} endIcon={<ArrowCircleDownIcon />}>
              새로운 메시지가 있어요
            </Button>
          </Box>
          <Alert variant="filled" severity="info" className="mngrcamp-alert" sx={mngrCampAlertStyle}>
            {liveAnnounceText}
          </Alert>
          <ModalChatName modalCommand={chatNameInputModalCommand} callBack={chatNameModalProcDone} />
        </Stack>
      </Grid>
      <LoadingCircle loading={isLoading} />
      <ChatBlock ref={chatBlockRef} broadSeq={props.broadSeq} callBack={callbackChatBlock} />
    </Grid>
  );
};

export default ChatAdmin;
