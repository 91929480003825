import React, { useEffect, useState, createRef, useRef, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./ClientIVSPlayer.css";

interface propsType {
  playbackUrl: string;
}

let player: any = null;

const TestPlayer = (props: propsType) => {
  const [text, setText] = useState("start");
  const [error, setError] = useState("");
  useEffect(() => {
    if (props.playbackUrl !== "") {
      console.log("props.playbackUrl : ", props.playbackUrl);
      // https://d3kpu9n9u1nkac.cloudfront.net/ivs/v1/146279097934/raNDLDOkbb1u/2023/1/16/0/16/R2rVitJTGXjT/media/hls/720p60/playlist.m3u8
      // https://d3kpu9n9u1nkac.cloudfront.net/ivs/v1/146279097934/raNDLDOkbb1u/2023/1/16/7/28/WGZg5vKK5TqB/media/hls/master.m3u8
      mediaPlayerScriptLoaded();
    }
  }, [props.playbackUrl]);

  let PlayerState: any;
  const mediaPlayerScriptLoaded = () => {
    // This shows how to include the Amazon IVS Player with a script tag from our CDN
    // If self hosting, you may not be able to use the create() method since it requires
    // that file names do not change and are all hosted from the same directory.

    const MediaPlayerPackage = window.IVSPlayer;
    //const { IVSPlayer } = window;

    // First, check if the browser supports the Amazon IVS player.
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn("The current browser does not support the Amazon IVS player.");
      return;
    }

    PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    player = MediaPlayerPackage.create();
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));
    player.addEventListener(PlayerState.READY, onReady);
    player.addEventListener(PlayerState.IDLE, onIdle);
    player.addEventListener(PlayerState.PLAYING, onStatePlaying);
    player.addEventListener(PlayerState.ENDED, onStateEnd);
    player.addEventListener(PlayerEventType.ERROR, onError);
    // player.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);

    // Setup stream and play
    player.setAutoQualityMode(true);
    player.setLiveLowLatencyEnabled(true);
    player.setAutoplay(false);
    player.setMuted(true);
    player.setVolume(1.0);

    player.load(
      "https://d3kpu9n9u1nkac.cloudfront.net/ivs/v1/146279097934/v0LSy9qtbwUw/2023/1/26/5/2/t7aSbdrKXiJr/media/hls/master.m3u8?start=2023-01-26T13:00:28-08:00"
    );
  };

  const onIdle = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    setText(newState);
  };

  const onReady = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    console.info("Player getQualities - : ", player.getQualities());
    player.setAutoplay(true);
    player.play();
    setText(newState);
  };

  const onStatePlaying = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    console.info("Player getQualities - : ", player.getQualities());
    setText(newState);
  };

  const onStateEnd = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    setText(newState);
  };

  const onError = (cue: any) => {
    const newState = player.getState();
    console.info("Player State - ", cue);
    setError(cue.code + "/" + cue.message + "/" + cue.source + "/" + cue.type);
  };

  return (
    <>
      <video
        id="videoPlayer"
        className="th-client-video"
        poster={"/images/2020_com_topimg4.jpg"}
        playsInline
        muted
      ></video>
      <div className="test-text">
        {text}/{error}
      </div>
    </>
  );
};

export default TestPlayer;
