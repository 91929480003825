import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";

// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

// Interface
import { HttpClientApi } from "../../../service/client-rest-api";

// Component
import { useInterval } from "../../../utilities/UseInterval";

// Local CSS
import "./LiveNotice.css";

interface propsType {
  newQnAMsg: any;
  broad_info: any;
  isNotFloat: any;
  isFloat: any;
}

const LiveNotice = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class

  const [noticeText, setNoticeText] = useState(""); // 롤링영역 공지사항 최신 텍스트
  const [openNotice, setOpenNotice] = useState(false); // 공지사항, Q&A 상세보기

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setNoticeText("");
      setOpenNotice(false);
    };
  }, []);

  // 부모 컴포넌트에서 공지사항 변경시 최신화
  useEffect(() => {
    let tmpNoticeText = props.broad_info.broad_notice;
    tmpNoticeText = tmpNoticeText.replaceAll("<br />", " ").replaceAll("\n", " ");
    setNoticeText(tmpNoticeText);
  }, [props.broad_info.broad_notice]);

  // 공지사항 슬라이더 관련 ------------------------------------
  // for useInterval
  // const delay: number = 4000; // 4초마다 변경
  // const [isRunning, setIsRunning] = useState(true); // SetInterval 실행
  // const noticeSliderRef: any = useRef(); // slider ref
  // 슬라이딩할 텍스트
  // const [rollingTexts, setRollingTexts] = useState<any>([]);

  // 슬라이딩 구현 (한 row의 height가 제일 중요)
  // useInterval(
  //   () => {
  //     if (rollingTexts.length > 0) {
  //       if (noticeSliderRef.current.children.length > 0) {
  //         if (noticeSliderRef.current.children.length > 1) {
  //           // 리스트를 위로 올림. 에니메이션 추가
  //           noticeSliderRef.current.className = "notice-silder-list-animation";
  //           setTimeout(() => {
  //             if (noticeSliderRef.current !== null) {
  //               noticeSliderRef.current.className = "notice-silder-list"; // 에니메이션 초기화
  //               // 올라간 리스트를 삭제한다.
  //               noticeSliderRef.current.removeChild(noticeSliderRef.current.children[0]);
  //               // 마지막 하나 남았을때 아래에 기존 리스트를 다시 붙인다.
  //               if (noticeSliderRef.current.children.length === 1) {
  //                 for (let text of rollingTexts) {
  //                   const newElement = document.createElement("div");
  //                   newElement.className = "notice-silder-item";
  //                   const textElement = document.createElement("div");
  //                   textElement.className = "notice-silder-item-text";
  //                   const frontElement = document.createElement("span");

  //                   if (text.startsWith("공지")) {
  //                     text = text.slice(3);
  //                     frontElement.className = "notice-silder-item-1";
  //                     frontElement.innerHTML = '<img src="/images/noticeIcon.svg">&nbsp;공지&nbsp;';
  //                     newElement.onclick = noticeOpen;
  //                   } else if (text.startsWith("QnA")) {
  //                     text = text.slice(4);
  //                     frontElement.className = "notice-silder-item-2";
  //                     frontElement.innerHTML = '<img src="/images/questionIcon.svg">&nbsp;QnA&nbsp;';
  //                     newElement.onclick = qnaOpen;
  //                   }
  //                   const textNode = document.createTextNode(text);
  //                   textElement.appendChild(textNode);

  //                   newElement.appendChild(frontElement); // 로고 이미지 추가
  //                   newElement.appendChild(textElement); // 텍스트 추가
  //                   noticeSliderRef.current.appendChild(newElement);
  //                 }
  //               }
  //             }
  //           }, 2000); // 에니메이션 시간과 동일하게 세팅
  //         }
  //       }
  //     }
  //   },
  //   isRunning ? delay : null
  // );

  // 상세보기 Drawer Control
  const handleNoticeDrawerClose = () => {
    setOpenNotice(false);
    setTimeout(() => {
      props.isFloat();
    }, 200);
  };
  const handleNoticeDrawerOpen = () => {
    props.isNotFloat();

    setTimeout(() => {
      setOpenNotice(true);
    }, 200);
  };

  // 마스킹 처리
  function maskingChar(strChar: string) {
    if (strChar === undefined || strChar === "") {
      return "";
    }
    return strChar.substring(0, 3) + "".padEnd(strChar.substring(3, strChar.length).length, "*");
  }

  return (
    <>
      {noticeText.length > 0 ? (
        <div className={`thd-live-notice-wrapper`}>
          <Box className={`thd-live-notice-root`} onClick={handleNoticeDrawerOpen}>
            <Box className="thd-live-notice-item">
              <Box component="img" className="thd-live-notice-img" src="/images/notice.png" />
              <span>&nbsp;</span>
              <Typography
                className="thd-live-notice-text"
                fontFamily="Noto Sans KR"
                fontWeight="700"
                fontSize="14px"
                fontStyle="normal"
                color="#FFFFFF"
              >
                {"공지사항"}
              </Typography>
            </Box>
            {/* 슬라이더 */}
            {/* <div className={`notice-slider`}> */}
            {/* <div className="notice-silder-item" onClick={handleNoticeDrawerOpen}> */}
            {/* <span className="notice-silder-item-1"> */}
            {/* <img src="/images/noticeIcon.svg" /> */}
            {/* &nbsp;공지&nbsp; */}
            {/* </span> */}
            {/* <div className="notice-silder-item-text">{noticeText}555</div> */}
            {/* </div> */}
            {/* <div ref={noticeSliderRef} className="notice-silder-list">
                {rollingTexts.map((text: string, index: number) => {
                  return (
                    <div
                      key={index}
                      className="notice-silder-item"
                      onClick={text.startsWith("공지") ? noticeOpen : qnaOpen}
                    >
                      {text.startsWith("공지") ? (
                        <>
                          <span className="notice-silder-item-1">
                            <img src="/images/noticeIcon.svg" />
                            &nbsp;공지&nbsp;
                          </span>
                          <div className="notice-silder-item-text">{text.slice(2)}</div>
                        </>
                      ) : (
                        <>
                          <span className="notice-silder-item-2">
                            <img src="/images/questionIcon.svg" />
                            &nbsp;QnA&nbsp;
                          </span>
                          <div className="notice-silder-item-text">{text.slice(3)}</div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div> */}
            {/* </div> */}
          </Box>
          {/* 상세보기 */}
          <Drawer
            PaperProps={{ elevation: 0, style: { borderTopRightRadius: "16px", borderTopLeftRadius: "16px" } }}
            anchor="bottom"
            open={openNotice}
            onClose={handleNoticeDrawerClose}
            className="thd-live-more-body"
          >
            <Box
              sx={{
                width: "100%",
                height: "60vh",
                bgcolor: "while",
                borderRadius: "16px",
                position: "relative",
                padding: "18px",
                overflow: "hidden",
              }}
            >
              {/* 공지사항 상세보기 상단 */}
              <Box className="thd-live-notice-drawer-top">
                <Box component="img" src="/images/modal-notice.png" className="thd-live-notice-drawer-top-img" />
                <div className="thd-live-notice-drawer-top-text">&nbsp;공지사항</div>
                <Box className="thd-live-notice-drawer-close">
                  <Box component="img" src="/images/notice-close.svg" onClick={handleNoticeDrawerClose} />
                </Box>
              </Box>
              <Box className="thd-live-notice-more" sx={{ display: "flex", width: "100%", whiteSpace: "pre-line" }}>
                <Typography
                  component="div"
                  fontFamily="Noto Sans KR"
                  fontWeight="400"
                  fontSize="14px"
                  fontStyle="normal"
                  color="#777777"
                >
                  {props.broad_info.broad_notice}
                </Typography>
              </Box>
            </Box>
          </Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default forwardRef(LiveNotice);
