import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Login from "./page/Login";
import LoginFrame from "./page/LoginFrame";
import ChannelManagement from "./page/ChannelManagement";
import SettingManagement from "./page/SettingManagement";
import ClientChannels from "./page/ClientChannels";
import CodeManagement from "./page/CodeManagement";
import AdminManagement from "./page/AdminManagement";
import LiveHome from "./page/LiveHome";
import LiveMain from "./page/LiveMain";
import LiveFrame from "./page/LiveFrame";
import MyAccount from "./page/MyAccount";
import DevInfo from "./page/DevInfo";
import ProgressStoreCodeMng from "./page/ProgressStoreCodeMng";
import TemplatePreview from "./page/TemplatePreview";
import LiveTheHyundai from "./page/TheHyundai/LiveTheHyundai";
import LiveTheHyundaiAndroid from "./page/TheHyundai/LiveTheHyundaiAndroid";
import LiveTheHyundaiIOS from "./page/TheHyundai/LiveTheHyundaiIOS";
import Test from "./page/TheHyundai/Test";
import TheHyundaiShare from "./page/TheHyundai/TheHyundaiShare";
import TheHyundaiGame from "./page/TheHyundai/TheHyundaiGame";

import Box from "@mui/material/Box";

import { userState } from "../interface/MainInterface";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

const MainContainer = (props: propsType) => {
  useEffect(() => {
    const pathName: string[] = window.location.pathname.split("/");
    if (pathName.length > 2) {
      if (pathName[1] === "clientchannels" || pathName[1] === "live" || pathName[1] === "stream") {
        document.body.style.overscrollBehaviorY = "none";
        document.body.style.height = "100%";
        document.body.style.minHeight = "100%";
      } else {
        document.body.style.overscrollBehaviorY = "auto";
        document.body.style.height = "100%";
        document.body.style.minHeight = "100%";
      }
    } else {
      document.body.style.overscrollBehaviorY = "auto";
      document.body.style.height = "100%";
      document.body.style.minHeight = "100%";
    }

    window.scrollTo(0, 0);
  }, [window.location]);

  const loginSuccess = () => {
    window.location.href = "/";
  };

  return (
    <Box id="MainContainer" sx={{ flex: 1, paddingY: 0, paddingX: 0, backgroundColor: "transparent" }}>
      <Routes>
        <Route path="/" element={<LiveHome userState={props.userState} webViewStatus={props.webViewStatus} />} />
        <Route path="/login" element={<Login userState={props.userState} callBack={loginSuccess} />} />
        <Route path="/loginFrame" element={<LoginFrame userState={props.userState} />} />
        <Route path="/channelmanagement" element={<ChannelManagement userState={props.userState} />} />
        <Route path="/setting" element={<SettingManagement userState={props.userState} />} />
        <Route path="/codemanagement" element={<CodeManagement userState={props.userState} />} />
        <Route path="/adminmanagement" element={<AdminManagement userState={props.userState} />} />
        <Route
          path="/clientchannels"
          element={<ClientChannels userState={props.userState} webViewStatus={props.webViewStatus} />}
        />
        <Route
          path="/livehome"
          element={<LiveHome userState={props.userState} webViewStatus={props.webViewStatus} />}
        />
        <Route
          path="/live/:broadseq"
          element={<LiveMain userState={props.userState} webViewStatus={props.webViewStatus} type="live" />}
        />
        <Route
          path="/rehearsal/:broadseq"
          element={<LiveMain userState={props.userState} webViewStatus={props.webViewStatus} type="rehearsal" />}
        />
        <Route
          path="/stream/:broadseq"
          element={<LiveTheHyundai userState={props.userState} webViewStatus={props.webViewStatus} />}
        />
        <Route
          path="/androidFrame/:broadseq"
          element={<LiveTheHyundaiAndroid userState={props.userState} webViewStatus={props.webViewStatus} />}
        />
        <Route
          path="/iOSFrame/:broadseq"
          element={<LiveTheHyundaiIOS userState={props.userState} webViewStatus={props.webViewStatus} />}
        />
        <Route
          path="/test/:broadseq"
          element={<Test userState={props.userState} webViewStatus={props.webViewStatus} />}
        />
        <Route path="/TheHyundaiShare" element={<TheHyundaiShare />} />
        <Route path="/TheHyundaiGame/:broadseq/:gameseq" element={<TheHyundaiGame />} />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/devinfo" element={<DevInfo userState={props.userState} />} />
        <Route path="/progresscode" element={<ProgressStoreCodeMng userState={props.userState} />} />
        <Route path="/template/:templateseq" element={<TemplatePreview />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Box>
  );
};

export default MainContainer;
