import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useForm } from "react-hook-form";
import { appConfig } from "../../../config/Config";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import {
  HttpClientApi,
  ListChannelMessageParams,
  SendChannelMessageParams,
} from "../../../service/client-rest-api";
import { userState, broadSetting } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../service/common_utils";
import * as ChatConstant from "./ChatConstant";
import "./Chat.css";

interface propsType {
  broad_seq: string;
  channelArn: string;
  adminArn: string;
  chatUrl: string;
  screenMode: string;
  chat_open: boolean;
  hidden_menu: boolean;
  player_id: string;
  chatInitCallback: any;
  streamEventCallback: any;
  videoPosition: string;
  userState: userState;
  setting: broadSetting;
  nickNameChange: any;
  isNotFloat: any;
  isFloat: any;
  chat_close: any;
  gameEventCallback: any;
}

interface chatSendForm {
  msg: string;
}

const ChatVerNoSdk = (props: propsType, ref: any) => {
  const ClientApi = new HttpClientApi();
  const [cUtils] = useState(() => new CommonUtils());
  const chatTextSize: string = "14px";

  const chatInnerRef = useRef<HTMLDivElement>();
  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents

  const [channelArn, setChannelArn] = useState<string>(""); // Member Variable
  const [adminArn, setAdminArn] = useState<string>("");
  const [broadSeq, setBroadSeq] = useState<string>("");
  const [messages, setMessages] = useState<any>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({
    display: "none",
  });
  const [managerCampaignText, setManagerCampaignText] = useState("");
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [visibility, setVisibility] = useState("visible");

  // Ref for subscribe callback
  const messagesRef = useRef(messages);
  const channelArnRef = useRef(channelArn);
  const adminArnRef = useRef(adminArn);
  const broadSeqRef = useRef(broadSeq);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setChannelArn("");
      setAdminArn("");
      setBroadSeq("");
      setMessages([]);
      setNextToken(undefined);
      setNeedScrollEnd(true);
      setBtnNewMsgBoxStyle({ display: "none" });
      setManagerCampaignText("");
      setVisibleStartIndex(0);
      setVisibility("visible");
    };
  }, []);

  // Form for send message to chime channel
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<chatSendForm>();

  useEffect(() => {
    if (props.hidden_menu && !props.chat_open) {
      setVisibility((visibility) => "hidden");
      setBtnNewMsgBoxStyle({ display: "none" });
    } else {
      setVisibility((visibility) => "visible");
      setBtnNewMsgBoxStyle(
        needScrollEnd ? { display: "none" } : { display: "inline" }
      );
    }
  }, [props.hidden_menu, props.chat_open]);

  // Chime Connection
  useEffect(() => {
    if (props.channelArn !== "" && props.chatUrl !== "") {
      setChannelArn(props.channelArn);
      setAdminArn(props.adminArn);
      setBroadSeq(props.broad_seq);

      // 소켓 시작
      const connectionInit = new WebSocket(props.chatUrl);
      connectionInit.onopen = (event) => {};

      connectionInit.onclose = (event) => {
        // If the websocket closes, remove the current chat token
        console.error(
          "Disconnected to the chat room.",
          new Date().toISOString()
        );
        console.error("Disconnected to the chat room.", event);
        // props.chat_close();
      };

      connectionInit.onerror = (event) => {
        console.error("Chat room websocket error observed:", event);
      };

      connectionInit.onmessage = (event) => {
        messagesProcessor(event);
      };

      return () => {
        connectionInit.close();
      };
    }
  }, [props.channelArn, props.adminArn, props.chatUrl]);

  // subscript callback 연동용 Ref 최신화
  useEffect(() => {
    messagesRef.current = messages;
    channelArnRef.current = channelArn;
    adminArnRef.current = adminArn;
    broadSeqRef.current = broadSeq;
  });

  // 관리자 이벤트 수신시 Toast 메세지를 띄우주기
  useEffect(() => {
    if (managerCampaignText !== "") {
      setTimeout(() => {
        setManagerCampaignText("");
      }, 2000);
    }
  }, [managerCampaignText]);

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      if (needScrollEnd) {
        setBtnNewMsgBoxStyle({ display: "none" });
        scrollToBottom();
      } else {
        if (!props.hidden_menu) setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, needScrollEnd]);

  // 차임 메시지 처리용 프로세서
  const messagesProcessor = async (event: any) => {
    const data = JSON.parse(event.data);
    const eventType = data.Headers["x-amz-chime-event-type"];

    switch (eventType) {
      case "SESSION_ESTABLISHED":
        getListChannelMessages(broadSeqRef.current);
        break;
      case "CREATE_CHANNEL_MESSAGE":
        const msgPayload = JSON.parse(data.Payload);
        if (channelArnRef.current === msgPayload?.ChannelArn) {
          processChannelMessage(msgPayload);
        }
        break;
      case "REDACT_CHANNEL_MESSAGE":
      case "UPDATE_CHANNEL_MESSAGE":
      case "DELETE_CHANNEL_MESSAGE":
      case "DENIED_CREATE_CHANNEL_MESSAGE":
      case "FAILED_CREATE_CHANNEL_MESSAGE":
      case "DENIED_UPDATE_CHANNEL_MESSAGE":
      case "FAILED_UPDATE_CHANNEL_MESSAGE":
      case "PENDING_CREATE_CHANNEL_MESSAGE":
      case "PENDING_UPDATE_CHANNEL_MESSAGE":
      case "CREATE_CHANNEL":
      case "UPDATE_CHANNEL":
      case "DELETE_CHANNEL":
      case "CREATE_CHANNEL_MEMBERSHIP":
      case "UPDATE_CHANNEL_MEMBERSHIP":
      case "DELETE_CHANNEL_MEMBERSHIP":
      default:
        console.info("Not covered EventType:" + eventType);
    }
  };

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (broad_seq: string) => {
    const param: ListChannelMessageParams = {
      broad_seq: broad_seq,
      next_token: nextToken,
      list_mode: appConfig.listChannelMode,
    };

    await ClientApi.list_channel_messages(param).then((result: any) => {
      if (result.result_code === "200") {
        let normalMessages = result.result_body.Messages.filter(isNormalMsg); // 일반 메세지만 표시
        setMessages(normalMessages);
        // 안드로이드 삼성 브라우저에서 채팅 메세지 처음에 안보여서 이렇게 히든 후 비져블 처리함...
        setVisibility("hidden");
        setVisibility("visible");
        props.chatInitCallback(true);
        setNeedScrollEnd(true);
      }
    });
  };

  const isNormalMsg = (element: any) => {
    if (element.Metadata === "" || element.Metadata === undefined) {
      return true;
    } else if (
      // 채팅 금지 처리
      element.Metadata !== undefined &&
      element.Metadata === "chat_blocked" &&
      element.Sender.Arn === props.userState.userArn
    ) {
      return true;
    }
  };

  // 차임 메시지 도착(실시간)시 처리용 프로세스
  const processChannelMessage = async (message: any) => {
    const promise = Promise.resolve(message);
    const newMessage = await promise.then((m) => m);

    if (newMessage.ChannelArn !== props.channelArn) return;
    let isDuplicate = false;
    messagesRef.current.forEach((m: any, i: number, self: any) => {
      if ((m.response?.MessageId || m.MessageId) === newMessage.MessageId) {
        isDuplicate = true;
        self[i] = newMessage;
      }
    });

    // payload의 길이 때문에 단발성(NON_PERSISTENT) 메시지 기능을 관리자 메시지로 사용한다.
    if (
      !isDuplicate &&
      newMessage.Persistence === ChatConstant.Persistence.NON_PERSISTENT
    ) {
      const metadataText = JSON.parse(newMessage.Content);
      if (metadataText.prot === "manager_campaign")
        handleManagerCampaign(metadataText.contents);
      if (metadataText.prot === "stream_state")
        handleStreamState(metadataText.event_name);
      if (metadataText.prot === "game")
        handleGame(metadataText.prot, metadataText.gameSeq);
      if (metadataText.prot === "game_draw") {
        handleGame(metadataText.prot, metadataText.gameSeq);
      }
    }

    let newMessages: any = [...messagesRef.current];
    if (
      !isDuplicate &&
      newMessage.Persistence === ChatConstant.Persistence.PERSISTENT
    ) {
      // 채팅 금지 처리
      if (!isBlocked(newMessage)) {
        newMessages = [...newMessages, newMessage];
        setMessages(newMessages);
      }
    }
  };

  // 채팅 금지 처리
  const isBlocked = (element: any) => {
    if (
      element.Metadata !== undefined &&
      element.Metadata === "chat_blocked" &&
      element.Sender.Arn !== props.userState.userArn
    )
      return true;
  };

  // 채팅 메시지 전송
  const sendMessage = async (msg: string, meta: string) => {
    try {
      let param: SendChannelMessageParams = {
        broad_seq: props.broad_seq,
        content: msg,
        user_id: props.userState.id,
      };

      await ClientApi.send_channel_message(param).then((res) => {});
    } catch (e) {
      console.error("send msg ERROR : ", e);
    }
    setNeedScrollEnd(true);
    setBtnNewMsgBoxStyle({ display: "none" }); // 최신메세지 버튼
  };

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;
    const newStyle = {
      left: "0px",
      height: style.height,
      width: "100%",
    };

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);

    let textShadow = "";
    if (props.setting.chat_shadow)
      textShadow = "1px 1px 1px " + props.setting.chat_shadow_color;

    return (
      <ListItem style={visibleStartIndex > 0 ? style : newStyle} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={
                  messages[index].Sender.Arn === props.adminArn
                    ? {
                        fontSize: chatTextSize,
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        backgroundColor: "transparent",
                        border: "solid 1px",
                        borderColor: "snow",
                        pl: 1,
                        pr: 1,
                      }
                    : {
                        fontSize: chatTextSize,
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        pl: 1,
                        pr: 1,
                      }
                }
              >
                <div>
                  <span
                    style={
                      messages[index].Sender.Arn === props.adminArn
                        ? {
                            color: "#F2FF5A", // 어드민, 노랑색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            // textShadow: textShadow
                          }
                        : messages[index].Sender.Arn === props.userState.userArn
                        ? {
                            color: "#80C9FF", // 본인, 파랑색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            // textShadow: textShadow,
                          }
                        : {
                            color: "#ebeaea", // 일반 회원(어드민 제외), 회색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            // textShadow: textShadow,
                          }
                    }
                    onClick={() => {
                      nickNameChange(messages[index].Sender.Arn);
                    }}
                  >
                    {messages[index].Sender.Arn === props.adminArn
                      ? messages[index].Sender.Name
                      : cUtils.maskingChar(messages[index].Sender.Name)}
                  </span>
                  {messages[index].Content.split(appConfig.quote_separator).map(
                    (line: string, idx: number) => {
                      const repleLength = messages[index].Content.split(
                        appConfig.quote_separator
                      ).length;
                      let styleCss: any = {
                        color: props.setting.chat_text_color,
                        wordBreak: "break-all",
                      };
                      if (idx !== repleLength - 1) styleCss.color = "#ebeaea";
                      if (idx === 0) {
                        return (
                          <span key={index} style={styleCss}>
                            {line}
                          </span>
                        );
                      } else {
                        styleCss.color = "#59f4f4";
                        return (
                          <span key={index + "-" + idx} style={styleCss}>
                            <br />
                            {appConfig.quote_separator + " " + line}
                          </span>
                        );
                      }
                    }
                  )}
                </div>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (chatInnerRef.current) chatInnerRef.current.style.marginTop = "auto";
    setVisibleStartIndex((visibleStartIndex) => e?.visibleStartIndex);
    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };

  // 스크롤 이벤트
  const evttOnScroll = (e: any) => {
    // props.isNotFloat();
  };

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current.scrollToItem(messages.length, "end");
    }
  };

  // 관리자 이벤트 수신시 Toast 메세지를 띄우주기 위한 핸들러
  const handleManagerCampaign = (contents: string) => {
    setManagerCampaignText(contents);
  };

  // EventBridge에서 IVS stream 상태에 따라 이벤트 수신
  const handleStreamState = (event_name: string) => {
    props.streamEventCallback(event_name);
  };

  const handleGame = (prot: string, data: string) => {
    props.gameEventCallback(prot, data);
  };

  const nickNameChange = (senderArn: string) => {
    if (senderArn !== props.userState.userArn) return;
    props.nickNameChange();
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    sendMessage,
    scrollToBottom,
  }));

  return (
    <>
      <Box
        id="thd-chat-root"
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            // width: "90%",
            height: "30vh",
            visibility: visibility,
          }
        }
        className="thd-chat-root white-shadow text-shadow"
      >
        <>
          {/* 체팅창 영역 */}
          <Box
            id="chat-box"
            component="div"
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
            className={visibleStartIndex > 0 ? "thd-gradient-box" : ""}
          >
            <Box sx={{ height: "100%", width: "100%" }}>
              <AutoSizer style={{ height: "100%", width: "100%" }}>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemSize={getRowHeight}
                    itemCount={messages.length}
                    overscanCount={5}
                    className="thd-chat-list"
                    innerRef={chatInnerRef}
                    ref={listRef}
                    onScroll={evttOnScroll}
                    onItemsRendered={evtItemRendered}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </Box>
            {/* 체팅창 스크롤 위로갈때 스크롤 아래로 이동하는 버튼 */}
            <Box className="btn-new-chat-hd" sx={btnNewMsgBoxStyle}>
              <Button
                sx={{
                  backgroundColor: "#ff376d",
                  "&:hover": { backgroundColor: "#ff376d" },
                }}
                variant="contained"
                size="small"
                onClick={scrollToBottom}
                endIcon={<ArrowCircleDownIcon />}
              >
                <span>최신 채팅으로 이동</span>
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default forwardRef(ChatVerNoSdk);
