import { useCallback, useEffect, useState } from "react";
import { broadSetting } from "../../../interface/MainInterface";
import "./ClientIVSPlayer.css";

interface propsType {
  endLoadInfo: boolean;
  broad_status: string;
  playbackUrl: string;
  streamState: string;
  videoStatusCallback: any;
  setting: broadSetting;
  muted: boolean;
  webViewStatus: string;
}
export let player: any = null;

export const ClientIVSPlayer = (props: propsType) => {
  const PLAYBACK_URL = props.playbackUrl;
  const [broadStatus, setBroadStatus] = useState("");
  const [qltyName, setqltyName] = useState("");
  const [latancy, setLatency] = useState(1000);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setqltyName("");
      setLatency(1000);
    };
  }, []);

  useEffect(() => {
    if (PLAYBACK_URL !== "") {
      mediaPlayerScriptLoaded();
    }
  }, [PLAYBACK_URL]);

  useEffect(() => {
    setBroadStatus(props.broad_status);
    if (props.broad_status === "START") player.load(PLAYBACK_URL);
    if (props.broad_status === "READY") player.load("");
    if (props.streamState === "Stream Start" && props.broad_status === "START") player.load(PLAYBACK_URL);
  }, [props.streamState, props.broad_status, PLAYBACK_URL]);

  // useEffect(() => {
  //   let intervalThreshold = 4;
  //   if (props.webViewStatus === "ios") intervalThreshold = 15;
  //   if (latancy > intervalThreshold) {
  //     console.log("느림");
  //     setBestQlty();
  //   }
  // }, [latancy]);

  const mediaPlayerScriptLoaded = () => {
    const MediaPlayerPackage = window.IVSPlayer;
    if (!MediaPlayerPackage.isPlayerSupported) {
      console.warn("The current browser does not support the Amazon IVS player.");
      return;
    }

    const PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    player = MediaPlayerPackage.create();
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));
    player.addEventListener(PlayerState.READY, onReady);
    player.addEventListener(PlayerState.IDLE, onIdle);
    player.addEventListener(PlayerState.PLAYING, onStatePlaying);
    player.addEventListener(PlayerState.ENDED, onStateEnd);
    player.addEventListener(PlayerEventType.ERROR, onError);
    player.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);
    player.addEventListener(PlayerEventType.AUDIO_BLOCKED, onAudioBlocked);
    player.addEventListener(PlayerEventType.INITIALIZED, onInitialized);
    player.addEventListener(PlayerEventType.MUTED_CHANGED, onMutedChanged);
    player.addEventListener(PlayerEventType.NETWORK_UNAVAILABLE, onNetworkUnavailable);
    player.addEventListener(PlayerEventType.PLAYBACK_RATE_CHANGED, onPlaybackRateChanged);
    player.addEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);
    player.addEventListener(PlayerEventType.PLAYBACK_BLOCKED, onPlaybackBlocked);

    window.addEventListener("focus", fncAutoPlay);
    function fncAutoPlay() {
      if (props.webViewStatus === "ios") {
        console.log("4444");
        player.setAutoplay(true);
        player.play();
      }
    }
    // Setup stream and play
    player.setAutoQualityMode(false);
    player.setLiveLowLatencyEnabled(true);
    player.setMuted(props.muted);
    player.setVolume(1.0);
    player.setAutoplay(true);

    if (props.broad_status === "START") {
      player.load(PLAYBACK_URL);
    }
  };

  const onIdle = useCallback(
    (cue: any) => {
      const newState = player.getState();
      console.info(`Player State - ${newState}`);
      props.videoStatusCallback("onIdle");
      window.addEventListener("visibilitychange", visibleCheck, false);
      function visibleCheck(e: any) {
        e.stopPropagation();
        if (document.visibilityState === "visible" && broadStatus === "START") {
          player.play();
        }
      }
      if (broadStatus === "START") {
        console.log("1111");
        player.play();
      }
    },
    [props, broadStatus]
  );

  const onReady = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onReady");
  };

  const onStatePlaying = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onStatePlaying");
  };

  const onStateEnd = (cue: any) => {
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onStateEnd");
  };

  const onError = useCallback(
    (err: any) => {
      console.warn("Player Event - ERROR:", err);
      props.videoStatusCallback("onError");
      if (broadStatus === "START") {
        console.log("333");
        player.play();
      }
    },
    [props, broadStatus]
  );

  const onTimeUpdate = (evt: any) => {
    setLatency(player.getLiveLatency());
  };
  const onAudioBlocked = (evt: any) => {
    console.log("onAudioBlocked");
  };
  const onInitialized = (evt: any) => {
    console.log("onInitialized");
  };
  const onMutedChanged = (evt: any) => {
    console.log("onMutedChanged");
  };
  const onNetworkUnavailable = (evt: any) => {
    console.log("onNetworkUnavailable");
  };
  const onPlaybackRateChanged = (evt: any) => {
    console.log("onPlaybackRateChanged");
  };
  const onQualityChanged = (evt: any) => {
    console.log("onQualityChanged : ", evt);
    setqltyName((qltyName) => evt.name);
  };
  const onPlaybackBlocked = (evt: any) => {
    console.log("onPlaybackBlocked");
  };

  const getBestQlty = () => {
    const liveQlty = player.getQualities();
    if (liveQlty.length) {
      let bestQltyIdx = 0;
      let bestHeigth = 100;
      liveQlty.map((obj: any, index: number) => {
        const currHeight = obj.height;
        if (currHeight >= bestHeigth) {
          bestHeigth = currHeight;
          bestQltyIdx = index;
        }
      });
      return liveQlty[bestQltyIdx];
    } else {
      return null;
    }
  };

  const setBestQlty = () => {
    const bestQlty = getBestQlty();
    // console.log("bestQlty : ", bestQlty);
    if (bestQlty !== null) {
      player.setQuality(bestQlty);
    }
  };

  return (
    <>
      <video
        id="videoPlayer"
        className="th-client-video"
        poster={
          props.setting.ready_img_url !== ""
            ? props.setting.ready_img_url
            : props.endLoadInfo
            ? "/images/2020_com_topimg4.jpg"
            : ""
        }
        playsInline
        muted
      ></video>
    </>
  );
};
