import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LoadingCircle from "../../utilities/LoadingCircle";

import CreateManager from "../../service/devInfo/CreateManager";
import GetManagerInfo from "../../service/devInfo/GetManagerInfo";
import GetManagerList from "../../service/devInfo/GetManagerList";
import UpdateManager from "../../service/devInfo/UpdateManager";
import DeleteManager from "../../service/devInfo/DeleteManager";
import ChangeManagerPassword from "../../service/devInfo/ChangeManagerPassword";
import CreateChannel from "../../service/devInfo/CreateChannel";
import GetChannelInfo from "../../service/devInfo/GetChannelInfo";
import GetChannelList from "../../service/devInfo/GetChannelList";
import UpdateChannel from "../../service/devInfo/UpdateChannel";
import IFrameDemo from "../../service/devInfo/IFrameDemo";
import { userState, androidWebAppInterface } from "../../interface/MainInterface";
import Header from "../Header";

interface propsType {
  userState: userState;
}

const DevInfo = (props: propsType) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const move = (id: string) => {
    window.location.href = `#${id}`;
  };

  window.addEventListener(
    "message",
    function (e: any) {
      if (e.origin === "http://192.168.0.4:3000" && e.data.msg !== undefined) {
        if (e.data.msg === "needLogin") {
          //로그인 처리
        } else if (e.data.msg === "getCoupon") {
          //쿠폰 발급여부 체크 및 잔여 리스트 조히

          // 쿠폰이 있는 경우 json > list에 데이터를 담아보낸다.
          let rtnData = {
            response: {
              coupons: [
                { couponId: "00001", price: "1000", type: "1" },
                { couponId: "00002", price: "3000", type: "2" },
              ],
            },
          };
          // 쿠폰이 없는 경우 빈 list를 보낸다.
          // let rtnData = {
          //   response: {
          //     coupons: [],
          //   },
          // };

          // 결과를 리턴
          e.source.postMessage(rtnData, e.origin);
        }
      }
    },
    false
  );

  return (
    <>
      <Header userState={props.userState} />
      <Box sx={{ width: "100%", flexGrow: 1, pb: 10, px: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 10 }}>
            <List sx={{ width: "100%" }} component="nav" aria-labelledby="index">
              <ListItemButton onClick={handleClick}>
                <ListItemText primary="목차" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("createManager");
                    }}
                  >
                    <ListItemText primary="관리자 등록 요청" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("getManagerInfo");
                    }}
                  >
                    <ListItemText primary="관리자 정보 조회" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("getManagerList");
                    }}
                  >
                    <ListItemText primary="관리자 리스트 조회" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("updateManager");
                    }}
                  >
                    <ListItemText primary="관리자 정보 수정" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("changeManagerPassword");
                    }}
                  >
                    <ListItemText primary="관리자 비밀번호 변경" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("deleteManager");
                    }}
                  >
                    <ListItemText primary="관리자 삭제" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("createChannel");
                    }}
                  >
                    <ListItemText primary="라이브 채널 생성" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("getChannelInfo");
                    }}
                  >
                    <ListItemText primary="채널 정보 조회" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("getChannelList");
                    }}
                  >
                    <ListItemText primary="채널 리스트 조회" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("updateChannel");
                    }}
                  >
                    <ListItemText primary="채널 정보 수정" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      move("iframe");
                    }}
                  >
                    <ListItemText primary="iFrame 샘플" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>
          </Grid>
          <div id="createManager" className="width100">
            <CreateManager />
          </div>
          <div id="getManagerInfo" className="width100">
            <GetManagerInfo />
          </div>
          <div id="getManagerList" className="width100">
            <GetManagerList />
          </div>
          <div id="updateManager" className="width100">
            <UpdateManager />
          </div>
          <div id="changeManagerPassword" className="width100">
            <ChangeManagerPassword />
          </div>
          <div id="deleteManager" className="width100">
            <DeleteManager />
          </div>
          <div id="createChannel" className="width100">
            <CreateChannel />
          </div>
          <div id="getChannelInfo" className="width100">
            <GetChannelInfo />
          </div>
          <div id="getChannelList" className="width100">
            <GetChannelList />
          </div>
          <div id="updateChannel" className="width100">
            <UpdateChannel />
          </div>
          <div id="iframe" className="width100">
            <IFrameDemo />
          </div>
        </Grid>
      </Box>
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default DevInfo;
