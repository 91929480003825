import { useEffect, useState } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

import { CommonUtils } from "../../service/common_utils";
import { getRandomColor, getRandomNum } from "../../utilities/utils";
import { androidWebAppInterface, broadSetting } from "../../interface/MainInterface";

import Heart from "../../utilities/Heart";
import Smile from "../../utilities/Smile";

interface propsType {
  like_count: number;
  broad_seq: string;
  extra_type: string;
  hidden_menu: boolean;
  webViewStatus: string;
  chat_click: any;
  chat_open: boolean;
  chat_btn_ready: boolean;
  like_click: any;
  new_like_count: number;
  broad_prod_list: any;
  broad_status: string;
  broadQnaList: any;
  broadQnaOpen: any;
  pip_callback: any;
  setting: broadSetting;
  screenMode: string;
}

declare var android: androidWebAppInterface;

const LiveRight = (props: propsType) => {
  const [cUtils] = useState(() => new CommonUtils());
  const [hearts, setHearts] = useState([] as any);
  const [smiles, setSmiles] = useState([] as any);

  const btnLikeClickCallback = () => {
    props.like_click();
    like_up();
  };

  const like_up = () => {
    const max = 1;
    const target = getRandomNum(0, 2);
    if (target === 1) {
      for (let i = 0; i < max; i++) {
        setSmiles((smiles: any) => [
          ...smiles,
          {
            id: Date.now() + "/" + i,
          },
        ]);
      }
    } else {
      for (let i = 0; i < max; i++) {
        setHearts((hearts: any) => [
          ...hearts,
          {
            id: Date.now() + "/" + i,
            color: getRandomColor(),
          },
        ]);
      }
    }
  };

  const removeHeart = () => {
    const activeHearts = [...hearts];
    activeHearts.shift();
    setHearts(activeHearts);
  };

  const removeSmile = () => {
    const activeSmiles = [...smiles];
    activeSmiles.shift();
    setSmiles(activeSmiles);
  };

  useEffect(() => {
    if (props.new_like_count > 0) like_up();
  }, [props.new_like_count]);

  const handleChatOpen = () => {
    props.chat_click(true);
  };

  const [btnChatClass, setBtnChatclass] = useState({ padding: 0, display: "none" });
  useEffect(() => {
    !props.chat_open && props.chat_btn_ready
      ? setBtnChatclass({ padding: 0, display: "flex" })
      : setBtnChatclass({ padding: 0, display: "none" });
  }, [props.chat_open, props.chat_btn_ready]);

  const [iconSize, setIconSize] = useState("3.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setIconSize(`${props.setting.icon_size}vmax`);
    else setIconSize(`${props.setting.icon_v_size}vmax`);
  }, [props.screenMode, props.setting.icon_size, props.setting.icon_v_size]);

  const handleProductList = () => {
    handleProdDrawerOpen();
  };

  const [openProd, setOpenProd] = useState(false);
  const [prodList, setProdList] = useState([] as any);
  const handleProdDrawerClose = () => setOpenProd(false);
  const handleProdDrawerOpen = () => {
    setProdList(
      props.broad_prod_list.map((obj: any, index: number) => {
        return (
          <ListItem key={`coupon${obj.prod_id}`} disablePadding>
            <ListItemButton onClick={() => clickProd(obj)}>
              <ListItemText primary={obj.prod_title} />
            </ListItemButton>
          </ListItem>
        );
      })
    );
    setOpenProd(true);
  };

  const clickProd = (prod: any) => {
    if (props.webViewStatus === "android") {
      props.pip_callback(true); // 이거 일단 안먹음
      android.openWindow(prod.prod_callback);
    } else {
      window.open(prod.prod_callback, "_blank");
    }
  };

  return (
    <>
      <Box sx={props.hidden_menu ? { display: "none" } : { display: "flex" }}>
        <Stack direction="column" spacing={1}>
          <div className="heart_root">
            <div id="divheartActions" className="heart-actions no-scroll">
              <div className="like-wrapper">
                {hearts.map((item: any, idx: any) => (
                  <Heart key={item.id} color={item.color} removeHeart={removeHeart} />
                ))}
                {smiles.map((item: any, idx: any) => (
                  <Smile key={item.id} removeSmile={removeSmile} />
                ))}
              </div>
            </div>
          </div>
          <span className="like-count svc-text font-small">
            {props.like_count > 0 ? cUtils.numericComma(props.like_count) : "0"}
          </span>
          {/* 좋아요 */}
          <IconButton sx={{ padding: 0 }} onClick={btnLikeClickCallback}>
            <FavoriteBorderIcon sx={{ fontSize: iconSize }} className="color-snow" />
          </IconButton>
          {/* 채팅 */}
          <IconButton
            sx={props.broad_status !== "VOD" ? btnChatClass : { padding: 0, display: "none" }}
            onClick={handleChatOpen}
          >
            <CommentOutlinedIcon sx={{ fontSize: iconSize }} className="color-snow" />
          </IconButton>
          {/* 상품 */}
          <IconButton
            sx={props.extra_type === "P001" ? { padding: 0, display: "none" } : { padding: 0, display: "flex" }}
            onClick={handleProductList}
          >
            <LocalMallIcon sx={{ fontSize: iconSize }} className="color-orangered" />
          </IconButton>
        </Stack>
      </Box>
      <Drawer
        PaperProps={{ elevation: 0, style: { borderRadius: "16px" } }}
        anchor="bottom"
        open={openProd}
        onClose={handleProdDrawerClose}
        className="live-more-body"
      >
        <Box
          sx={{ width: "100%", height: "320px", bgcolor: "while", borderRadius: "16px" }}
          className="live-more-body"
          onClick={handleProdDrawerClose}
        >
          상품 리스트
          <nav aria-label="Set Resolution">
            <List>{prodList}</List>
          </nav>
        </Box>
      </Drawer>
    </>
  );
};

export default LiveRight;
