import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { appConfig } from "../../../config/Config";

import "./TheHyundaiShare.css";

interface propsType {}
declare const window: any;
const TheHyundaiShare = (props: propsType) => {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(appConfig.kakao_app_key);
  }

  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const makeUrl = () => {
    return (
      "https://m.thehyundai.com/front/app/selectDeepLinkGate.thd?T=BD&R=L00&V=" +
      queryParams.hdBroadNo +
      "&LIveAllpageGroup=previous_page&LIveAllbannerName=Share_Kakao"
    );
  };

  const fncShareKakao = () => {
    window.Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: queryParams.broadTitle,
        imageUrl: queryParams.broadImgSrc,
        link: {
          mobileWebUrl: makeUrl(),
          webUrl: makeUrl(),
        },
      },
      buttons: [
        {
          title: "입장하기",
          link: {
            mobileWebUrl: makeUrl(),
            webUrl: makeUrl(),
          },
        },
      ],
    });
  };

  const handleShareClose = () => {};
  return (
    <>
      <div className="share-root">
        <Grid container spacing={2} component="div">
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Box id="btn-kakao-share" onClick={fncShareKakao}>
                <Stack direction="column" spacing={1}>
                  <img className="thd-share-btn-icon" src="/images/icon-kakao.png" alt="" />
                  <Typography>{"카카오톡"}</Typography>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TheHyundaiShare;
