import { useEffect, useState } from "react";
import { Hub } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../../style.css";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { userState } from "../../interface/MainInterface";
import * as AuthMain from "../../service/auth/AuthMain";

import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);
I18n.setLanguage("kr");

interface propsType {
  userState: userState;
  callBack: any;
}

const Login = (props: propsType) => {
  const [alertText, setAlertText] = useState("");
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    console.info("userState : ", props.userState);
    if (props.userState.id !== "") {
      if (window.confirm("이미 로그인되어 있습니다. 다른 아이디로 로그아웃 하시겠습니까?")) {
        signOut();
      } else {
        props.callBack();
      }
    }
  }, []);

  async function signOut() {
    const result = await AuthMain.signOut();
    if (result) window.location.reload();
  }

  const openAlert = (time: number) => {
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, time);
  };

  const listener = (data: any) => {
    setAlert(false);
    setAlertText("");
    switch (data.payload.event) {
      case "signIn":
        props.callBack();
        break;
      case "signUp":
        break;
      case "signUp_failure":
        setAlertText("계정생성에 실패했습니다. [" + data.payload.data.message + "]");
        openAlert(2000);
        break;
      case "signOut":
        break;
      case "signIn_failure":
        setAlertText("로그인에 실패했습니다. ID와 패스워드를 확인하세요.");
        openAlert(2000);
        break;
      case "tokenRefresh":
        break;
      case "tokenRefresh_failure":
        break;
      case "forgotPassword_failure":
        setAlertText(data.payload.data.message);
        openAlert(2000);
        break;
      case "forgotPasswordSubmit_failure":
        setAlertText(data.payload.data.message);
        openAlert(2000);
        break;
      case "configured":
        break;
      default:
        console.error("not support : ", data);
    }
  };

  useEffect(() => {
    Hub.listen("auth", listener);
  }, []);
  return (
    <>
      <div className="login-wrapper">
        <Authenticator loginMechanisms={["email"]} className="login-font" />
      </div>
      <Alert
        variant="filled"
        severity="warning"
        className="login-alert"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlert(false);
              setAlertText("");
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={alert ? { display: "flex" } : { display: "none" }}
      >
        {alertText}
      </Alert>
    </>
  );
};

export default Login;
