/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
const awsmobile = {
  aws_project_region: "ap-northeast-2",

  Auth: {
    region: "ap-northeast-2",
    userPoolId: "ap-northeast-2_aBNjLswYE", // Please change this value.
    userPoolWebClientId: "791i3s0ssuf4fqfcsa93mh47q6", // Please change this value.
    identityPoolId: "ap-northeast-2:9df1e5f0-816e-4edf-872f-d4baa2007678",
  },
  Storage: {
    AWSS3: {
      bucket: "thdliveprod-channelbackendstack-wa-ap-northeast-2-38100",
      region: "ap-northeast-2",
    },
  },
  aws_cloud_logic_custom: [
    {
      name: "AdminApiName",
      endpoint: "https://gpum3edf66.execute-api.ap-northeast-2.amazonaws.com/Prod/",
      region: "ap-northeast-2",
    },
    {
      name: "ThehyundaiApiName",
      endpoint: "https://gpum3edf66.execute-api.ap-northeast-2.amazonaws.com/Prod/",
      region: "ap-northeast-2",
    },
    {
      name: "MobileApiName",
      endpoint: "https://2pqce8e9fb.execute-api.ap-northeast-2.amazonaws.com/Prod/",
      region: "ap-northeast-2",
    },
    {
      name: "ExternalApiName",
      endpoint: "https://jzw5thym55.execute-api.ap-northeast-2.amazonaws.com/Prod/",
      region: "ap-northeast-2",
    },
  ],
};

export default awsmobile;
