import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import {
  DataGrid,
  GridRenderCellParams,
  GridCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";

import { userState } from "../../interface/MainInterface";
import {
  HttpChannelApi,
  StartGameParams,
  GetGameListParams,
  GetGameApplyListParams,
  SetDrawGameParam,
  SendChimeMessageParam,
} from "../../service/channel-api";
import { appConfig } from "../../config/Config";
import { CommonUtils } from "../../service/common_utils";
import LoadingCircle from "../../utilities/LoadingCircle";
import { useInterval } from "../../utilities/UseInterval";

interface propsType {
  userState: userState;
  broadInfo: any;
  channelArn: string;
  adminArn: string;
  getChannelInfo: any;
  sendIoTMessageMeta: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface IGameListCols {
  pk: string;
  sk: string;
  flag: string;
  game_status: string;
  manager_id: string;
  timeout: string;
  start_dtm: string;
  draw_number: number;
  winner_flag: string;
  draw_result: [];
}

interface IWinnerListCols {
  pk: string;
  sk: string;
  user_id: string;
  cust_no: string;
}

interface IApplyListCols {
  pk: string;
  sk: string;
  cust_no: string;
  apply_dtm: string;
}

const Persistence = {
  PERSISTENT: "PERSISTENT",
  NON_PERSISTENT: "NON_PERSISTENT",
};

const MessageType = {
  STANDARD: "STANDARD",
  CONTROL: "CONTROL",
};

const gameTypes: any = {
  apply: "응모하기",
};

const chApi = new HttpChannelApi();

const TabGame = (props: propsType, ref: any) => {
  const [cUtils] = useState(() => new CommonUtils());
  const [loading, setLoading] = useState(false);

  const [drawNumber, setDrawNumber] = useState<any>(0);
  const [timeoutMin, setTimeoutMin] = useState<any>(0);
  const [timeoutSec, setTimeoutSec] = useState<any>(0);

  const [gameType, setGameType] = useState("");
  const [gameTypeText, setGameTypeText] = useState("");
  const [remainTime, setRemainTime] = useState<any>("");
  const [dueTime, setDuetime] = useState("");
  const [drawNumberIng, setDrawNumberIng] = useState<any>(0);
  const [applyNumberIng, setApplyNumberIng] = useState<any>(0);
  const [gamePk, setGamePk] = useState("");
  const [gameSk, setGameSk] = useState("");

  const [ingGameList, setIngGameList] = useState<IGameListCols[]>([]);
  const [gameList, setGameList] = useState<IGameListCols[]>([]);
  const [applyList, setApplyList] = useState<IApplyListCols[]>([]);
  const [winnerList, setWinnerList] = useState<IWinnerListCols[]>([]);

  const ingGameListCols = [
    {
      field: "start_dtm",
      headerName: "start_dtm",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
    {
      field: "flag",
      headerName: "flag",
      width: 100,
      renderCell: (param: GridRenderCellParams<string>) => {
        return <span>{getGameTypeText(param.value)}</span>;
      },
    },
    { field: "game_status", headerName: "status", width: 100 },
    { field: "manager_id", headerName: "manager", width: 200 },
    {
      field: "timeout",
      headerName: "timeout",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return param.value !== "" ? (
          <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span>
        ) : (
          "제한없음"
        );
      },
    },
  ];

  const getGameTypeText = (input: string | undefined) => {
    if (input !== undefined) {
      return gameTypes[input];
    } else {
      return "";
    }
  };

  const gameListCols = [
    {
      field: "start_dtm",
      headerName: "start_dtm",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
    { field: "flag", headerName: "flag", width: 100 },
    { field: "game_status", headerName: "status", width: 100 },
    { field: "manager_id", headerName: "manager", width: 200 },
    {
      field: "timeout",
      headerName: "timeout",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return param.value !== "" ? (
          <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span>
        ) : (
          "제한없음"
        );
      },
    },
    {
      field: "winner_flag",
      headerName: "winner",
      width: 200,
      renderCell: (param: GridRenderCellParams<string>) => {
        return param.value !== "" ? (
          <Button
            variant="contained"
            onClick={() => {
              downloadWinnerExcelList(param.value!);
            }}
          >
            당첨자 명단
          </Button>
        ) : (
          <></>
        );
      },
    },
  ];

  const applyListCols = [
    { field: "sk", headerName: "아이디", width: 150 },
    { field: "cust_no", headerName: "고객번호", width: 150 },
    {
      field: "apply_dtm",
      headerName: "참여시간",
      flex: 1,
      renderCell: (param: GridRenderCellParams<string>) => {
        return <span>{dayjs(param.value).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getGames = async () => {
    initForm();
    setSelectionModel([]);
    const param: GetGameListParams = {
      broad_seq: props.broadInfo.broad_seq,
    };

    const res = await chApi.get_game_list(param);
    console.log(res);
    if (res.code === "200") {
      setGameList(res.response.game_list);
      // let game_ing: boolean = false;
      let tempList: any = [];
      for (const gameInfo of res.response.game_list) {
        if (gameInfo.game_status === "START") {
          tempList.push(gameInfo);
        }
      }
      setIngGameList(tempList);
      if (tempList.length > 0) setSelectionModel([tempList[0].sk]);
      handleClose();
    } else {
      console.log("error : ", res.response.error_msg);
    }
  };

  const setGame = (flag: string) => {
    handleOpen();
  };

  const startGame = async (flag: string) => {
    console.log("START GAME : ", flag);
    const param: StartGameParams = {
      broad_seq: props.broadInfo.broad_seq,
      user_id: props.userState.id,
      flag: flag,
      draw_number: drawNumber,
      timeout: timeoutMin * 60 + timeoutSec * 1,
    };

    const res = await chApi.start_game(param);
    console.log("start_game : " + res.response.game_seq);
    if (res.code === "200") {
      // 차임 메세지 전송
      sendChimeMessage(
        "game",
        props.broadInfo.broad_seq,
        res.response.game_seq,
        flag
      );
      getGames();
    } else if (res.code === "201") {
      window.alert(
        "이미 진행중인 게임이 있습니다. 기존게임을 종료 후 다시 시도하세요."
      );
    } else {
      console.error(res.response.error_msg);
    }
  };

  const getApplyList = async (seq: string) => {
    const param: GetGameApplyListParams = {
      gameSeq: seq,
    };

    const res = await chApi.get_game_apply_list(param);
    if (res.code === "200") {
      setApplyList(res.response.apply_list);
    } else {
      console.error(res.response.error_msg);
    }
  };

  useEffect(() => {
    setApplyNumberIng(applyList.length);
  }, [applyList]);

  const sendChimeMessage = async (
    prot: string,
    broadSeq: string,
    gameSeq: string,
    flag: string
  ) => {
    console.log("broadSeq : ", broadSeq);
    console.log("gameSeq : ", gameSeq);
    console.log("flag : ", flag);

    const content: any = {
      prot: prot,
      gameSeq: gameSeq,
      broadSeq: broadSeq,
      flag: flag,
    };

    const param: SendChimeMessageParam = {
      chime_channel_arn: props.channelArn,
      content: JSON.stringify(content),
      sender_user_arn: props.adminArn,
      type: MessageType.STANDARD,
      persistence: Persistence.NON_PERSISTENT,
    };

    const res = await chApi.put_chime_message(param);
    console.log("res : ", res);
  };

  const drawGame = async () => {
    console.log("gamePk : ", gamePk);
    console.log("gameSk : ", gameSk);
    let target = [];
    for (const user of applyList) {
      console.log("user : ", user);
      target.push({
        user_id: user.sk,
        cust_no: user.cust_no,
        apply_dtm: user.apply_dtm,
      });
    }
    console.log("target : ", target);

    if (target.length < drawNumberIng) {
      window.alert("추첨인원보다 참여인원이 적습니다.");
      return;
    }

    // 추첨
    const totalTargetCnt = target.length - drawNumberIng;
    let drawResult: any = [];
    while (target.length > totalTargetCnt) {
      let movenum = target.splice(
        Math.floor(Math.random() * target.length),
        1
      )[0];
      drawResult.push(movenum);
    }
    console.log("drawResult : ", drawResult);

    const param: SetDrawGameParam = {
      pk: gamePk,
      sk: gameSk,
      user_id: props.userState.id,
      drawResult: drawResult,
    };
    console.log("param : ", param);
    const res = await chApi.draw_game(param);
    console.log("res : ", res);
    getGames();

    sendChimeMessage(
      "game_draw",
      props.broadInfo.broad_seq,
      gameSk,
      "game_draw"
    );
  };

  // 필드 초기화
  const initForm = () => {
    setIngGameList([]);
    setLoop(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getGames,
    initForm,
  }));

  const [loop, setLoop] = useState(true);
  // 방송이 송출전이면 방송 남은시간 계산
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      if (dueTime !== "") {
        const remainSec = cUtils.calRemainSec(dueTime);
        setRemainTime(cUtils.convertRemainText(remainSec));
        if (remainSec < 0) {
          setRemainTime(cUtils.convertRemainText(0));
          setLoop(false);
        }
      }
    },
    loop ? delay : null
  );

  const [applyLoop, setApplyLoop] = useState(false);
  const applyDelay: number = 5000;
  useInterval(
    () => {
      getApplyList(gameSk);
    },
    applyLoop ? applyDelay : null
  );

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  useEffect(() => {
    console.log("selectionModel : ", selectionModel);
    console.log("ingGameList : ", ingGameList);
    if (selectionModel.length > 0) {
      for (const gameInfo of ingGameList) {
        if (gameInfo.sk === selectionModel[0]) {
          setGamePk(gameInfo.pk);
          setGameSk(gameInfo.sk);
          setGameType(gameInfo.flag);
          setGameTypeText(getGameTypeText(gameInfo.flag));
          setDuetime(gameInfo.timeout);
          setDrawNumberIng(gameInfo.draw_number);
          getApplyList(gameInfo.sk);
          setLoop(true);
          setApplyLoop(true);
        }
      }
    } else {
      setGamePk("");
      setGameSk("");
      setGameType("");
      setGameTypeText("");
      setDuetime("");
      setDrawNumberIng(0);
      setApplyList([]);
      setLoop(false);
      setApplyLoop(false);
    }
  }, [selectionModel, ingGameList]);

  const downloadWinnerExcelList = (sk: string) => {
    for (const item of gameList) {
      if (item.winner_flag === sk) {
        // sheet header
        let winnerContent: any = [["아이디", "고객번호", "응모시간"]];
        // 각 행 순번 만들기
        let winnerIndex = 1;
        // 내용 생성
        for (const winner_info of item.draw_result) {
          const detail = [
            winner_info["user_id"],
            winner_info["cust_no"],
            dayjs(winner_info["apply_dtm"]).format("YYYY-MM-DD HH:mm:ss"),
          ];
          winnerContent = [...winnerContent, detail];
          winnerIndex += 1;
        }
        // 컬럼 넓이
        const colWidth = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }];

        const winnerSheetName = "당첨자 내역";

        // 파일명
        const fileName =
          dayjs(String(item.start_dtm)).format("YYYY-MM-DD HHmm") +
          "_당첨자내역_" +
          props.broadInfo.broad_title +
          ".xlsx";
        cUtils.downloadExcel(
          winnerContent,
          colWidth,
          winnerSheetName,
          fileName
        );

        return;
      }
    }

    alert("당첨자 다운로드는 게임 종료 후 가능합니다.");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box component={"div"}>
        <Grid container spacing={1} sx={{ paddingX: 0 }} component="div">
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 1 }} elevation={5}>
              <div style={{ width: "100%", height: "300px" }}>
                <DataGrid
                  rows={ingGameList}
                  columns={ingGameListCols}
                  pageSize={100}
                  getRowId={(row) => row.sk}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 1 }} elevation={5}>
              <Stack sx={{ width: "100%" }} direction={"column"} spacing={1}>
                <Stack sx={{ width: "100%" }} direction={"row"} spacing={1}>
                  <TextField
                    label="게임타입"
                    className="bt-md"
                    value={gameTypeText}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="추첨인원"
                    className="bt-md"
                    value={drawNumberIng}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="참여인원"
                    className="bt-md"
                    value={applyNumberIng}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  <TextField
                    label="남은시간"
                    className="bt-md"
                    value={remainTime}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Stack>
                <div style={{ width: "100%", height: "300px" }}>
                  <DataGrid
                    rows={applyList}
                    columns={applyListCols}
                    pageSize={100}
                    getRowId={(row) => row.sk}
                  />
                </div>
                <Button
                  variant="contained"
                  className="bt-md"
                  fullWidth
                  onClick={() => {
                    drawGame();
                  }}
                >
                  추첨하기
                </Button>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
        {/* 본문 영역 */}
        <Paper sx={{ width: "100%", p: 2 }} elevation={5}>
          <Box sx={{ width: "100%" }}>
            <Stack
              sx={{ width: "100%" }}
              direction={{ xs: "column" }}
              spacing={2}
            >
              <Button
                variant="contained"
                className="bt-md"
                fullWidth
                onClick={() => {
                  setGame("apply");
                }}
              >
                응모하기 게임 시작
              </Button>
              <div style={{ width: "100%", height: "490px" }}>
                <DataGrid
                  rows={gameList}
                  columns={gameListCols}
                  pageSize={100}
                  getRowId={(row) => row.sk}
                />
              </div>
            </Stack>
          </Box>
        </Paper>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack sx={{ width: "100%" }} direction="column" spacing={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              추첨게임을 시작 하시겠습니까?
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              참여한 사람 중 n명을 추첨하는 게임입니다.
            </Typography>
            <TextField
              label="추첨인원"
              className="bt-md"
              value={drawNumber}
              variant="outlined"
              type="number"
              size="small"
              fullWidth
              onChange={(e) => {
                setDrawNumber(e.target.value);
              }}
            />
            <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
              <TextField
                label="제한시간(분)"
                className="bt-md"
                value={timeoutMin}
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                onChange={(e) => {
                  setTimeoutMin(e.target.value);
                }}
              />
              <TextField
                label="제한시간(초)"
                className="bt-md"
                value={timeoutSec}
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                onChange={(e) => {
                  setTimeoutSec(e.target.value);
                }}
              />
            </Stack>
            <Button
              variant="contained"
              className="bt-md"
              fullWidth
              onClick={() => {
                startGame("apply");
              }}
            >
              시작
            </Button>
          </Stack>
        </Box>
      </Modal>
      <LoadingCircle loading={loading} />
    </Box>
  );
};

export default forwardRef(TabGame);
