import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridCellParams, GridToolbarFilterButton, GridRenderCellParams } from "@mui/x-data-grid";
import {
  HttpCodeApi,
  GetCodeListParams,
  GetCodeDetailParams,
  CreateCodeParams,
  DeleteCodeParams,
} from "../../service/code-rest-api";
import { userState, androidWebAppInterface } from "../../interface/MainInterface";
import Header from "../Header";

interface propsType {
  userState: userState;
}
interface IGridCols {
  code: string;
  code_name: string;
  code_status: string;
  code_type: string;
  pk: string;
  sk: string;
}

const CodeManagement = (props: propsType) => {
  const apiHandler = new HttpCodeApi();

  // 그리드 로딩 컨트롤
  const [loading, setIsLoading] = useState(true);
  const [loadingS, setIsLoadingS] = useState(false);

  // 입력필드 동기화 및 전역변수
  const [LargeCode, setPicLCode] = useState<string>(""); // 전역
  const [LargeCodeName, setLargeCodeName] = useState<string>("");
  const [SmallCode, setSmallCode] = useState<string>("");
  const [SmallCodeName, setSmallCodeName] = useState<string>("");

  // 대분류 코드 그리드 컬럼 설정
  const [lCodeRows, setLCodeRows] = React.useState<IGridCols[]>([]);
  const lCodeColumns = [
    {
      field: "sk",
      headerName: "",
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      width: 60,
    },
    { field: "code", headerName: "Code", width: 120 },
    { field: "code_name", headerName: "Name", flex: 1 },
  ];

  // 소분류 코드 그리드 컬럼 설정
  const [sCodeRows, setSCodeRows] = React.useState<IGridCols[]>([]);
  const sCodeColumns = [
    {
      field: "sk",
      headerName: "",
      renderCell: (param: GridRenderCellParams<string>) => {
        return (
          <>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
      width: 60,
    },
    { field: "code", headerName: "Code", width: 120 },
    { field: "code_name", headerName: "Name", flex: 1 },
  ];

  // 대분류 코드 조회
  const getCodeList = async () => {
    const param: GetCodeListParams = {
      large_code: "",
    };

    const res = await apiHandler.get_code_list(param);
    setLCodeRows(res.result_body);
    setIsLoading(false);
  };

  // 소분류 코드 조회
  const fncGetCodeDetail = async (largeCode: string) => {
    setIsLoadingS(true);
    const sParam: GetCodeDetailParams = {
      large_code: largeCode,
    };

    const res = await apiHandler.get_code_detail(sParam);
    if ("" + res.result_code === "200") {
      setSCodeRows(res.result_body);
    }
    setIsLoadingS(false);
  };

  // 대분류 코드 추가
  const fncAddLCode = async () => {
    if (LargeCodeName.trim() === "") {
      alert("대분류 코드의 이름을 입력하세요.");
      return;
    }

    const msg = "대분류 코드를 추가 하시겠습니까?";
    if (window.confirm(msg)) {
      const param: CreateCodeParams = {
        type: "large",
        large_code: "",
        large_code_name: LargeCodeName,
        small_code: "",
        small_code_name: "",
      };
      const res = await apiHandler.create_code(param);
      if ("" + res.result_code !== "200") {
        alert("[ERROR] " + res.result_body);
        return;
      }
      window.location.reload();
    }
  };

  // 소분류 코드 추가
  const fncAddSCode = async () => {
    if (LargeCode.trim() === "") {
      alert("대분류 코드를 먼저 선택해주세요.");
      return;
    }
    if (SmallCode.trim() === "") {
      alert("소분류 코드를 입력하세요.");
      return;
    }
    if (SmallCodeName.trim() === "") {
      alert("소분류 코드 이름을 입력하세요.");
      return;
    }

    const msg = "소분류 코드를 추가 하시겠습니까?";
    if (window.confirm(msg)) {
      const param: CreateCodeParams = {
        type: "small",
        large_code: LargeCode,
        large_code_name: "",
        small_code: SmallCode,
        small_code_name: SmallCodeName,
      };
      const res = await apiHandler.create_code(param);
      if ("" + res.result_code !== "200") {
        alert("[ERROR] " + res.result_body);
        return;
      }

      // 입력 필드 초기화 및 새로고침
      setSmallCode("");
      setSmallCodeName("");
      fncGetCodeDetail(LargeCode);
    }
  };

  // Delete Large code
  const fncDelCode = async (param: GridCellParams) => {
    const msg = "선택하신 코드를 삭제처리 하시겠습니까?";
    if (!window.confirm(msg)) {
      return;
    }

    const dParam: DeleteCodeParams = {
      pk: param.row.pk,
      sk: param.row.sk,
      code: param.row.code,
      code_type: param.row.code_type,
    };

    const res = await apiHandler.delete_code(dParam);
    if ("" + res.result_code !== "200") {
      alert("[ERROR] " + res.result_body);
      return;
    }
    if (param.row.code_type === "LARGE") {
      window.location.reload();
    } else {
      setSmallCode("");
      setSmallCodeName("");
      fncGetCodeDetail(LargeCode);
    }
  };

  // 대분류 코드 셀 클릭시 이벤트 -> 소분류코드 조회 및 삭제 아이콘 이벤트
  const fncCellClick = (param: GridCellParams) => {
    if (param.row.code_type === "LARGE" && param.field !== "sk") {
      setPicLCode(param.row.code);
      fncGetCodeDetail(param.row.code);
    }
    if (param.field === "sk") {
      fncDelCode(param);
    }
  };

  React.useEffect(() => {
    getCodeList();
  }, []);

  return (
    <>
      <Header userState={props.userState} />
      <Grid container spacing={2} component="div" sx={{ px: 5, pb: 5 }}>
        {/* 타이틀 */}
        <Grid item xs={12}>
          <h1>Code Management</h1>
        </Grid>
        {/* 대분류 코드 */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} direction="column">
            <Stack spacing={1} direction="row">
              <Grid item xs={8}>
                <div>
                  <TextField
                    id="txtLargeCodeName"
                    label="Large Code Name"
                    className="bt-md"
                    defaultValue=""
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setLargeCodeName(e.target.value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button variant="outlined" className="bt-md" onClick={fncAddLCode}>
                    ADD
                  </Button>
                </Stack>
              </Grid>
            </Stack>
            <div className="datatable">
              <DataGrid
                rows={lCodeRows}
                columns={lCodeColumns}
                pageSize={7}
                rowsPerPageOptions={[7]}
                getRowId={(row) => row.code}
                onCellClick={fncCellClick}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: GridToolbarFilterButton,
                }}
                loading={loading}
              />
            </div>
          </Stack>
        </Grid>
        {/* 소분류 코드 */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={1} direction="column">
            <Stack spacing={1} direction="row">
              <Grid item xs={4}>
                <div>
                  <TextField
                    id="txtSmallCode"
                    label="Small Code"
                    className="bt-md"
                    variant="outlined"
                    size="small"
                    value={SmallCode}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setSmallCode(e.target.value);
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <div>
                  <TextField
                    id="txtSmallCodeName"
                    label="Small Code Name"
                    className="bt-md"
                    variant="outlined"
                    size="small"
                    value={SmallCodeName}
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setSmallCodeName(e.target.value);
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button variant="outlined" className="bt-md" onClick={fncAddSCode}>
                    ADD
                  </Button>
                </Stack>
              </Grid>
            </Stack>
            <div className="datatable">
              <DataGrid
                rows={sCodeRows}
                columns={sCodeColumns}
                pageSize={7}
                rowsPerPageOptions={[7]}
                getRowId={(row) => row.code}
                onCellClick={fncCellClick}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: GridToolbarFilterButton,
                }}
                loading={loadingS}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default CodeManagement;
