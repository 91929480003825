export const appConfig = {
  appInstanceUserArn: "",
  channelArn: "",
  chime_region: "us-east-1",
  listChannelMode: "recent" /*recent or total*/,
  app_client_id: "",
  app_client_secret: "",
  app_client_url: "",
  access_token: undefined,
  access_token_expire_time: undefined,
  external_credential: undefined,
  external_cred_expire_time: undefined,
  quote_separator: "⤷",
  vod_cloudfront_endpoint: "https://thd-vod.livehyundai.com",
  iotRegion: "ap-northeast-2",
  iotIdentityPollId: "ap-northeast-2:2d59dd6b-5a01-4bf2-87b8-a3b0cd2d8987",
  iotEndPoint: "a24the5wvo6x1d-ats.iot.ap-northeast-2.amazonaws.com",
  domain: "https://thd-live.livehyundai.com",
  broadTypeCode: "00002",
  progressStoreSelect: true,
  progressStoreCode: "00003",
  extra_type: "P001",
  chat_id_mask: true,
  kakao_app_key: "92bc87488cf1b89205df7513f9b729c6",
};

export const setUserArn = (arn: string) => {
  appConfig.appInstanceUserArn = arn;
};

export const setChArn = (arn: string) => {
  appConfig.channelArn = arn;
};

export const setAccessToken = (token: any, time: any) => {
  appConfig.access_token = token;
  appConfig.access_token_expire_time = time;
};

export const setExtCredential = (credential: any, time: any) => {
  appConfig.external_credential = credential;
  appConfig.external_cred_expire_time = time;
};
