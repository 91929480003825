import Box from "@mui/material/Box";
import "./LiveFooter.css";

interface propsType {
  broad_status: string;
  user_id: string;
  hidden_menu: boolean;
  sendMessage: any;
  iconSize: string;
  openChatInput: any;
}

const LiveChatBtn = (props: propsType) => {
  const onhandleChat = () => {
    props.openChatInput(true);
  };

  return (
    <>
      {props.broad_status === "VOD" ? (
        <></>
      ) : (
        <Box
          sx={
            props.hidden_menu
              ? {
                  flexGrow: 1,
                  display: "none",
                }
              : {
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "10px",
                }
          }
          onClick={onhandleChat}
        >
          <img className="thd-chat-btn-icon white-shadow" src="/images/chat.png" alt="" />
          <div className="thd-chat-btn-title white-shadow">채팅하기</div>
        </Box>
      )}
    </>
  );
};

export default LiveChatBtn;
