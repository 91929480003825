import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { appConfig } from "../../../config/Config";
import { useEffect } from "react";
import "./TheHyundaiGame.css";

interface propsType {}
declare const window: any;
const TheHyundaiGame = (props: propsType) => {
  const { broadseq, gameseq } = useParams();

  useEffect(() => {
    console.log("broadseq : ", broadseq);
    console.log("gameseq : ", gameseq);
  }, [broadseq, gameseq]);

  const fncTest = () => {
    window.alert("broadseq : " + broadseq + "/ gameseq" + gameseq);
  };

  return (
    <>
      <div className="thd-test-view">
        <Button variant="outlined" fullWidth onClick={fncTest}>
          FIND
        </Button>
      </div>
    </>
  );
};

export default TheHyundaiGame;
