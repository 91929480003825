import { useState, useRef, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { HttpChannelApi, GetChannelinfoParams } from "../../service/channel-api";
import { HttpClientApi, CBroadParams } from "../../service/client-rest-api";
import { userState } from "../../interface/MainInterface";
import { appConfig } from "../../config/Config";

import TabChannel from "./TabChannel";
import TabNotice from "./TabNotice";
import TabGame from "./TabGame";
import TabVod from "./TabVod";
import TabSetting from "./TabSetting";
import TabStatistics from "./TabStatistics";
import ChatAdmin from "../../service/chat/ChatAdmin";
import IoTCore from "../../service/iot/IoTClient";
import LoadingCircle from "../../utilities/LoadingCircle";

const emptyBroadInfo = {
  pk: "",
  broad_seq: "",
  broad_title: "",
  broad_desc: "",
  broad_start_tm: "",
  broad_stop_tm: "",
  broad_status: "",
  broad_prod_list: [],
  broad_notice: "",
  host_id: "",
  vod_url: "",
  vod_started_at: "",
  vod_start_sec: 0,
  vod_end_sec: 0,
  password: "",
  extra_type: "",
  progress_store: "",
  channel_img: "",
  channel_template: false,
};

const emptyIvsInfo = {
  channel_arn: "",
  ingest_endpoint: "",
  stream_key: "",
  playback_url: "",
};

const emptyChimeInfo = {
  app_instance_user_arn: "",
  chime_channel_arn: "",
};

interface propsType {
  userState: userState;
  reloadChannelList: any;
  openLivePreview: any;
  broadTypes: any;
  progressStores: any;
}
const ChannelDetail = (props: propsType, ref: any) => {
  const chApi = new HttpChannelApi();
  const clientApi = new HttpClientApi();
  const iotRef: any = useRef();
  const [loading, setLoading] = useState(false);

  const [pk, setPk] = useState("");
  const [broadSeq, setBroadSeq] = useState("");
  const [broadInfo, setBroadInfo] = useState(emptyBroadInfo);
  const [ivsInfo, setIvsInfo] = useState(emptyIvsInfo);
  const [chimeInfo, setChimeInfo] = useState(emptyChimeInfo);

  const [chatConn, setChatConn] = useState(false);
  const [chatUrl, setChatUrl] = useState("");

  const tabChannelRef: any = useRef();
  const tabVodRef: any = useRef();
  const tabNoticeRef: any = useRef();
  const tabGameRef: any = useRef();
  const tabSettingRef: any = useRef();
  const tabStatisticsRef: any = useRef();

  const [tabValue, setTabValue] = useState("channel");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (newValue === "chat" && broadSeq !== "" && !chatConn)
      enter_chat_channel(pk, broadSeq, props.userState.id, props.userState.nickName);
  };

  const getChannelInfo = async (_pk: string, _broadSeq: string) => {
    setPk((pk) => _pk);
    setBroadSeq((broadSeq) => _broadSeq);
    const param: GetChannelinfoParams = {
      pk: _pk,
      broad_seq: _broadSeq,
      st_add: "1",
    };
    setLoading(true);
    const res = await chApi.get_channel_info(param);
    if (res.result_code === "200") {
      setBroadInfo(res.result_body);
      setIvsInfo(res.ivs_info);
      setChimeInfo(res.chime_info);
      tabChannelRef.current.setChannelInfo(res.result_body);
      tabNoticeRef.current.setNoticeInfo(res.result_body);
      tabGameRef.current.getGames();
      tabVodRef.current.setVodInfo(res.result_body);
      tabSettingRef.current.setSettingData(res.result_body);
      tabStatisticsRef.current.getStatisticsData(_broadSeq);
      if (tabValue === "chat") enter_chat_channel(_pk, _broadSeq, props.userState.id, props.userState.nickName);
    } else {
      alert("에러 발생 : " + res.result_body);
    }
    setLoading(false);
  };

  const initForm = () => {
    setPk("");
    setBroadSeq("");
    setBroadInfo(emptyBroadInfo);
    setIvsInfo(emptyIvsInfo);
    setChimeInfo(emptyChimeInfo);
    tabChannelRef.current.initForm();
    tabNoticeRef.current.initForm();
    tabGameRef.current.initForm();
    tabVodRef.current.initForm();
    if (pk !== "" && broadSeq !== "") tabSettingRef.current.initForm();
    setChatUrl((chatUrl) => "");
  };

  const saveChannel = () => {
    if (pk === "" || broadSeq === "") {
      tabChannelRef.current.createChannel();
    } else {
      tabChannelRef.current.updateChannel();
    }
  };

  const deleteChannel = async () => {
    tabChannelRef.current.deleteChannel();
  };

  const reloadChannelInfo = () => {
    getChannelInfo(broadInfo.pk, broadInfo.broad_seq);
  };

  // 채팅방 입장
  const enter_chat_channel = async (_pk: string, _broadSeq: string, user_id: string, nick_name: string) => {
    const param: CBroadParams = {
      broad_seq: _broadSeq,
    };
    setChatUrl((chatUrl) => "");
    setLoading(true);
    const enter_res = await clientApi.enter_chat_channel(param, props.userState.isAuth, user_id, nick_name);
    if (enter_res.result_code === "200") setChatUrl((chatUrl) => enter_res.result_body.presigned_url);
    setLoading(false);
  };

  // 채팅방 입장 성공 여부
  const enterChatCallback = (result: boolean) => {
    console.info("Chat - Loadding finished & list channel messages end : ", result);
    setChatConn((chatConn) => true);
  };
  const streamEventCallback = (event_name: string) => {}; // 이 화면에서는 사용안함.

  const disconnectChat = () => {
    setChatConn((chatConn) => false);
  };

  // IoT message recieve callback
  function recieveIoTMessage(topic: any, payLoad: any, clientId: string) {}

  const sendIoTMessageMeta = (metaName: string) => {
    const topic = `live/${broadInfo.broad_seq}/metadata`;
    iotRef.current.sendIoTMessageMeta(topic, metaName);
  };

  const sendIoTMessage = (payLoad: any) => {
    const topic = `live/${broadInfo.broad_seq}/metadata`;
    iotRef.current.sendIoTMessage(topic, payLoad);
  };

  const openLivePreview = (broadSeq: string) => {
    props.openLivePreview(broadSeq);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getChannelInfo,
    initForm,
  }));

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    window.location.reload(); // IoT다시 맺기
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={1}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
          <Box sx={{ width: "100%", pb: "3px" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="fullWidth"
              aria-label="Channel Info"
              sx={{ m: 0, p: 0 }}
            >
              <Tab value="channel" label="채널상세" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
              <Tab value="notice" label="공지사항" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
              <Tab value="game" label="게임" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
              <Tab value="vod" label="VOD" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
              <Tab value="setting" label="설정" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
              <Tab value="statistics" label="통계" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
              <Tab value="chat" label="채팅" sx={{ p: 0, maxWidth: { sm: "10vh" } }} />
            </Tabs>
          </Box>
          <Button variant="contained" size="small" color="primary" onClick={initForm}>
            초기화
          </Button>
          <Button variant="contained" size="small" color={broadSeq === "" ? "warning" : "info"} onClick={saveChannel}>
            {broadSeq === "" ? "생성" : "수정"}
          </Button>
          {broadSeq === "" || broadInfo.broad_status === "START" ? (
            <></>
          ) : (
            <Button variant="contained" size="small" color="error" onClick={deleteChannel}>
              삭제
            </Button>
          )}
        </Stack>
        {/* 탭 - 채널 */}
        <Box sx={tabValue === "channel" ? { display: "flex", width: "100%" } : { display: "none" }}>
          <TabChannel
            ref={tabChannelRef}
            userState={props.userState}
            reloadChannelInfo={reloadChannelInfo}
            reloadChannelList={props.reloadChannelList}
            broadInfo={broadInfo}
            ivsInfo={ivsInfo}
            sendIoTMessageMeta={sendIoTMessageMeta}
            broadTypes={props.broadTypes}
            progressStores={props.progressStores}
          />
        </Box>
        {/* 탭 - 공지사항 */}
        <Box sx={tabValue === "notice" ? { display: "flex", width: "100%" } : { display: "none" }}>
          <TabNotice
            ref={tabNoticeRef}
            userState={props.userState}
            broadInfo={broadInfo}
            getChannelInfo={getChannelInfo}
            sendIoTMessageMeta={sendIoTMessageMeta}
          />
        </Box>
        {/* 탭 - 게임 */}
        <Box sx={tabValue === "game" ? { display: "flex", width: "100%" } : { display: "none" }}>
          <TabGame
            ref={tabGameRef}
            userState={props.userState}
            broadInfo={broadInfo}
            channelArn={chimeInfo.chime_channel_arn}
            adminArn={chimeInfo.app_instance_user_arn}
            getChannelInfo={getChannelInfo}
            sendIoTMessageMeta={sendIoTMessageMeta}
          />
        </Box>
        {/* 탭 - VOD */}
        <Box sx={tabValue === "vod" ? { display: "flex", width: "100%" } : { display: "none" }}>
          <TabVod ref={tabVodRef} userState={props.userState} broadInfo={broadInfo} getChannelInfo={getChannelInfo} />
        </Box>
        {/* 탭 - 설정 */}
        <Box sx={tabValue === "setting" ? { display: "flex", width: "100%" } : { display: "none" }}>
          <TabSetting
            ref={tabSettingRef}
            userState={props.userState}
            broadInfo={broadInfo}
            getChannelInfo={getChannelInfo}
            sendIoTMessageMeta={sendIoTMessageMeta}
            openLivePreview={openLivePreview}
          />
        </Box>
        {/* 탭 - 통계 */}
        <Box sx={tabValue === "statistics" ? { display: "flex", width: "100%" } : { display: "none" }}>
          <TabStatistics ref={tabStatisticsRef} broad_info={broadInfo} tabValue={tabValue} />
        </Box>
        {/* 탭 - 채팅 */}
        <Box sx={tabValue === "chat" ? { display: "flex", width: "100%" } : { display: "none" }}>
          {chatUrl !== "" ? (
            <ChatAdmin
              broadInfo={broadInfo}
              broadSeq={broadInfo.broad_seq}
              chatUrl={chatUrl}
              channelArn={chimeInfo.chime_channel_arn}
              adminArn={chimeInfo.app_instance_user_arn}
              chat_callback={enterChatCallback}
              streamEventCallback={streamEventCallback}
              userState={props.userState}
              tabValue={tabValue}
              disconnectChat={disconnectChat}
              sendIoTMessage={sendIoTMessage}
            />
          ) : (
            <Stack spacing={2} direction="column" sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", height: 674, p: 2 }} elevation={5}></Paper>
            </Stack>
          )}
        </Box>
        {/* 로딩바 */}
        <LoadingCircle loading={loading} />
      </Stack>
      {broadInfo.broad_seq !== undefined && (
        <IoTCore
          ref={iotRef}
          broadSeq={broadInfo.broad_seq}
          iotRegion={appConfig.iotRegion}
          iotIdentityPollId={appConfig.iotIdentityPollId}
          iotEndPoint={appConfig.iotEndPoint}
          recieveIoTMessage={recieveIoTMessage}
          iotLost={iotLost}
        />
      )}
    </Box>
  );
};

export default forwardRef(ChannelDetail);
