import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import dayjs from "dayjs";

// MUI
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Interface
import {
  HttpClientApi,
  ApplyGameParam,
  CheckGameParam,
  GetGameApplyListParams,
} from "../../../service/client-rest-api";

// Component
import { useInterval } from "../../../utilities/UseInterval";

// Local CSS
import "./LiveGame.css";
import { Grid, Stack } from "@mui/material";

interface propsType {
  gameInfo: any;
  userId: string;
  custNo: string;
  applyClick: any;
}

const LiveGame = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class

  const [btnText, setBtnText] = useState("응모하기");
  const [checkFinish, setCheckFinish] = useState(false);
  const [alreadyJoin, setAlreadyJoin] = useState(false);
  const [applyNum, setApplyNum] = useState(0);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      // setNoticeText("");
      // setOpenNotice(false);
    };
  }, []);

  // 이미 게임에 참여했는지 확인
  const checkGameState = async () => {
    const param: CheckGameParam = {
      game_info: props.gameInfo,
      user_id: props.userId,
    };

    const res = await clientApi.check_game(param);
    console.log("check game : ", res.response.result);
    if (res.response.result === "exist") setAlreadyJoin(true);
    setCheckFinish(true);
  };

  const applyGame = async () => {
    if (alreadyJoin) return;
    const param: ApplyGameParam = {
      game_info: props.gameInfo,
      user_id: props.userId,
      cust_no: props.custNo,
      apply_info: {},
    };

    const res = await clientApi.apply_game(param);
    console.log("res : ", res.response.result);
    if (res.result_code === "200") {
      if (res.response.result === "success") {
        props.applyClick();
        checkGameState();
      }
    }
  };

  const getGameApplyNum = async (seq: string) => {
    const param: GetGameApplyListParams = {
      gameSeq: seq,
    };

    const res = await clientApi.get_game_apply_list(param);
    if (res.code === "200") {
      const apply_num = res.response.apply_list.length;
      setApplyNum(apply_num);
    } else {
      setApplyNum(0);
      console.error(res.response.error_msg);
    }
  };

  const applyNumCallback = () => {
    setApplyNum((applyCount: number) => applyCount + 1);
  };

  useImperativeHandle(ref, () => ({
    applyNumCallback,
  }));

  useEffect(() => {
    checkGameState();
    getGameApplyNum(props.gameInfo.sk);
  }, []);

  useEffect(() => {
    if (alreadyJoin) setBtnText("응모완료");
    else setBtnText("응모하기");
  }, [alreadyJoin]);

  return (
    <>
      <div className={`thd-live-game-wrapper`}>
        {checkFinish && props.gameInfo.flag === "apply" ? (
          <Box className="thd-live-game-layout">
            <Box className="thd-live-game-root">
              <Box
                component="img"
                className="thd-live-game-img"
                src="/images/game-box.png"
              />
              <Box className="thd-live-game-item">
                <Grid item xs={2}>
                  <Stack spacing={0} direction="column">
                    <Stack
                      spacing={0.5}
                      direction="row"
                      className="thd-live-game-stack-row"
                    >
                      <Typography
                        className="thd-live-game-text"
                        fontFamily="Noto Sans KR"
                        fontWeight="700"
                        fontSize="16px"
                        fontStyle="normal"
                        color="#111"
                        text-overflow="ellipsis"
                        overflow="hidden"
                      >
                        추첨하기
                      </Typography>
                      <Box
                        sx={{ backgroundColor: "#FF376D", borderRadius: "8px" }}
                        className="thd-live-game-draw-box"
                      >
                        <Typography
                          className="thd-live-game-text"
                          fontFamily="Noto Sans KR"
                          fontWeight="700"
                          fontSize="12px"
                          fontStyle="normal"
                          color="#FFFFFF"
                          text-overflow="ellipsis"
                          overflow="hidden"
                        >
                          {props.gameInfo.draw_number}명
                        </Typography>
                      </Box>
                    </Stack>
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="400"
                      fontSize="11px"
                      fontStyle="normal"
                      color="rgba(17, 17, 17, 0.80)"
                      text-overflow="ellipsis"
                      overflow="hidden"
                    >
                      {applyNum}명 참여 중
                    </Typography>
                  </Stack>
                </Grid>
                {alreadyJoin ? (
                  <Button
                    sx={{
                      color: "#fff",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                      height: "40px",
                      marginLeft: "3px",
                      overflow: "hidden",
                      "&:hover": { border: "1px solid #fff" },
                    }}
                    variant="outlined"
                    onClick={() => {
                      applyGame();
                    }}
                  >
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="700"
                      fontSize="11px"
                      fontStyle="normal"
                    >
                      {btnText}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    sx={{
                      background: "#fff",
                      color: "#FF376D",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                      height: "40px",
                      marginLeft: "3px",
                      "&:hover": {
                        backgroundColor: "#fff",
                        border: "1px solid #fff",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      applyGame();
                    }}
                  >
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="700"
                      fontSize="11px"
                      fontStyle="normal"
                    >
                      {btnText}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default forwardRef(LiveGame);
