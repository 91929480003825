import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { HttpChannelApi } from "../../service/channel-api";
import LoadingCircle from "../../utilities/LoadingCircle";

interface propsType {
  broadSeq: string;
  callBack: any;
}

const ChatBlock = (props: propsType, ref: any) => {
  const ChannelApi = new HttpChannelApi();

  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [blockTime, setBlockTime] = useState("5");
  const [blockUserArn, setBlockUserArn] = useState("");
  const [blockUserName, setBlockUserName] = useState("");
  const handleBlockTimeChange = (event: SelectChangeEvent) => {
    setBlockTime(event.target.value as string);
  };

  const open = (message: any) => {
    console.log("message : ", message);
    setBlockUserArn(message.Sender.Arn);
    setBlockUserName(message.Sender.Name);
    setOpenDialog(true);
  };

  const fncNo = () => {
    setOpenDialog(false);
  };
  const fncYes = async () => {
    if (window.confirm(blockUserName + "님의 채팅을 금지하시겠습니까?")) {
      setIsLoading(true);
      const result = await ChannelApi.block_channel_message(props.broadSeq, blockUserArn, blockTime);

      if (result.result_code === "200" && result.result_body === "SUCCESS") {
        alert("채팅 금지를 완료했습니다.");
        setOpenDialog(false);
      } else {
        alert("오류가 발생했습니다. IT담당자에게 문의하세요.");
        console.log("result : ", result);
      }
      setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        fncNo();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"채팅금지"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ width: "500px", mt: 1 }} direction="column">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="search-use-yn-select-label">시간</InputLabel>
              <Select
                labelId="search-use-yn-select-label"
                id="search-use-yn-select-label"
                value={blockTime}
                label="시간"
                onChange={handleBlockTimeChange}
              >
                <MenuItem value={"5"}>5분</MenuItem>
                <MenuItem value={"10"}>10분</MenuItem>
                <MenuItem value={"3000"}>영구</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            fncNo();
          }}
        >
          취소
        </Button>
        <Button
          onClick={() => {
            fncYes();
          }}
        >
          적용
        </Button>
      </DialogActions>
      <LoadingCircle loading={isLoading} />
    </Dialog>
  );
};

export default forwardRef(ChatBlock);
