const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

const getRandomNum = (min: number, max: number) => {
  let num = Math.floor(Math.random() * max + 1) + min;
  return num;
};

const formatTimeStamp = (timestamp: string) => {
  const plus0 = (num: string) => `0${num.toString()}`.slice(-2);
  const TStoDate = new Date(timestamp);
  const DatetoString =
    plus0(TStoDate.getHours().toString()) +
    ":" +
    plus0(TStoDate.getMinutes().toString()) +
    ":" +
    plus0(TStoDate.getSeconds().toString());
  return DatetoString;
};

const getTimeSince = (dateTime: string | number | Date) => {
  let secondsDiff: number = Math.floor((+new Date() - new Date(dateTime).getTime()) / 1000);

  const days = Math.floor(secondsDiff / 86400);
  secondsDiff -= days * 86400;

  const hours = Math.floor(secondsDiff / 3600) % 24;
  secondsDiff -= hours * 3600;

  const minutes = Math.floor(secondsDiff / 60) % 60;
  secondsDiff -= minutes * 60;

  const seconds = Math.floor(secondsDiff % 60);

  let timeSince = "";

  if (days > 0) {
    timeSince += `${days}d `;
  }

  if (hours > 0) {
    timeSince += `${hours}h `;
  }

  if (minutes > 0) {
    timeSince += `${minutes}m `;
  }

  if (seconds > 0) {
    timeSince += `${seconds}s`;
  }

  return timeSince;
};

const hexToRgb = (hex: {
  replace: (
    arg0: RegExp,
    arg1: (m: any, r: any, g: any, b: any) => string
  ) => {
    (): any;
    new (): any;
    substring: { (arg0: number): { (): any; new (): any; match: { (arg0: RegExp): any[]; new (): any } }; new (): any };
  };
}) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r + r + g + g + b + b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const isElementInViewport = (el: { getBoundingClientRect: () => any }) => {
  const { innerHeight, innerWidth } = window;
  const { clientHeight, clientWidth } = document.documentElement;

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (innerHeight || clientHeight) &&
    rect.right <= (innerWidth || clientWidth)
  );
};

export { getRandomColor, getRandomNum, getTimeSince, hexToRgb, isElementInViewport, formatTimeStamp };
