import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Interface
import { HttpClientApi } from "../../../service/client-rest-api";

// Local CSS
import "./LiveGameDraw.css";
import { Grid, Stack } from "@mui/material";

interface propsType {
  gameWinner: any;
  drawEventCallback: any;
}

const LiveGameDraw = (props: propsType, ref: any) => {
  const clientApi = new HttpClientApi(); // API Class

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
    };
  }, []);

  const completeDraw = () => {
    props.drawEventCallback();
  };

  return (
    <>
      <div className="thd-live-game-draw-wrapper">
        <div className="pyro">
          <div className="before"></div>
          <div className="after"></div>
        </div>
        <Stack className="thd-live-game-draw-stack">
          <Box component="img" className="lthd-ive-game-draw-img" src="/images/game-draw.png" />
          <Typography
            className="thd-live-game-draw-text"
            fontFamily="Noto Sans KR"
            fontWeight="700"
            fontSize="21px"
            fontStyle="normal"
          >
            당첨을 축하드립니다!
          </Typography>
          <Box className="thd-live-game-draw-apply-box">
            <Grid container spacing={6}>
              {props.gameWinner.length > 0 &&
                props.gameWinner.map((winner: string, index: number) => {
                  return (
                    <Grid key={`${winner}#${index}`} item xs={4}>
                      <Typography
                        className="thd-live-game-draw-text"
                        fontFamily="Noto Sans KR"
                        fontWeight="700"
                        fontSize="14px"
                        fontStyle="normal"
                        color="#F2FF5A"
                      >
                        {winner}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Button
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              borderRadius: "6px",
              height: "40px",
              overflow: "hidden",
              marginTop: "10px",
              "&:hover": { border: "1px solid #fff" },
            }}
            variant="outlined"
            onClick={() => {
              completeDraw();
            }}
          >
            <Typography
              className="thd-live-game-draw-text"
              fontFamily="Noto Sans KR"
              fontWeight="700"
              fontSize="14px"
              fontStyle="normal"
            >
              확인
            </Typography>
          </Button>
        </Stack>
      </div>
    </>
  );
};

export default forwardRef(LiveGameDraw);
