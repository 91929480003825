import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { userState } from "../../../interface/MainInterface";
import { useInterval } from "../../../utilities/UseInterval";
import "./LiveCoupon.css";

interface propsType {
  user_id: string;
  userState: userState;
  coupons: any;
  postMsg: any;
  goLoginPage: any;
  hidden_menu: boolean;
}

const LiveCouponIOS = (props: propsType) => {
  const priceFormatter = new Intl.NumberFormat("ko");

  const [delay, setDelay] = useState(0);
  const [joinCoupon, setJoinCoupon] = useState<any>(null);
  const [durationCoupon, setDurationCoupon] = useState<any>(null);
  const [deleteCoupon, setDeleteCoupon] = useState(true);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setDelay(0);
      setJoinCoupon(null);
      setDurationCoupon(null);
      setDeleteCoupon(true);
    };
  }, []);

  useEffect(() => {
    getCoupon();
  }, [props.user_id]);

  useEffect(() => {
    for (const coupon of props.coupons) {
      console.log("coupon : ", coupon);
      if (coupon.liveCopnType === "join") {
        if (props.user_id !== "" && props.user_id !== undefined) {
          downloadCoupon(coupon);
        } else {
          viewJoinCoupon(coupon);
        }
      } else if (coupon.liveCopnType === "duration") {
        setDelay(coupon.durationTime);
        if (props.user_id !== "" && props.user_id !== undefined) {
          viewDurationCoupon(coupon);
        }
      }
    }
  }, [props.coupons]);

  const viewJoinCoupon = (coupon: any) => {
    // console.log("join coupon : ", coupon);
    setJoinCoupon(coupon);
  };

  const viewDurationCoupon = (coupon: any) => {
    // console.log("duration coupon : ", coupon);
    setDurationCoupon(coupon);
  };

  const getCoupon = () => {
    console.log("SEND getCoupon");
    try {
      (window as any).webkit.messageHandlers.hdLiveNativeCallbackHandler.postMessage({
        cmd: "getCouponList",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const downloadCoupon = (coupon: any) => {
    console.log("SEND downloadCoupon");
    // props.postMsg({ msg: "downloadCoupon", couponId: coupon.couponId }, "*");
    try {
      // android.downloadCoupon();
      (window as any).webkit.messageHandlers.hdLiveNativeCallbackHandler.postMessage({
        cmd: "downloadCoupon",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = () => {
    // 로그인 호출
    props.goLoginPage();
  };

  useEffect(() => {
    if (durationCoupon !== null) {
      if (delay > 0) {
        setDeleteCoupon(false);
      }
    }
  }, [durationCoupon]);

  const interval = 1000;
  useInterval(
    () => {
      if (delay <= 0) {
        downloadCoupon(durationCoupon);
        setDeleteCoupon((deleteCoupon) => true);
      }
      setDelay((delay) => delay - 1);
    },
    deleteCoupon ? null : interval
  );

  const minuteConverter = function (inputSec: number) {
    const min =
      (inputSec % 3600) / 60 < 10 ? "0" + Math.floor((inputSec % 3600) / 60) : Math.floor((inputSec % 3600) / 60);
    const sec = inputSec % 60 < 10 ? "0" + Math.floor(inputSec % 60) : Math.floor(inputSec % 60);
    return `${min}:${sec}`;
  };

  return (
    <>
      {(joinCoupon === null && durationCoupon === null) || (durationCoupon !== null && deleteCoupon === true) ? (
        <></>
      ) : (
        <Box
          className="thd-live-coupon-img-root"
          sx={props.hidden_menu ? { display: "none" } : { display: "flex", zIndex: "1000" }}
          onClick={joinCoupon !== null ? handleLogin : undefined}
        >
          <Box className="thd-live-coupon-img-container" sx={{}}>
            <Box className="thd-live-coupon-price">
              <Typography
                fontFamily="Noto Sans KR"
                fontSize="14px"
                lineHeight="20px"
                fontStyle="normal"
                fontWeight="700"
              >
                {/* 30,000원 */}
                {joinCoupon !== null ? (
                  joinCoupon.famtFxrtGbcd === "2" ? (
                    priceFormatter.format(joinCoupon.famtFxrtVal.replaceAll(" ", "")) + "원"
                  ) : (
                    joinCoupon.famtFxrtVal.replaceAll(" ", "") + "%"
                  )
                ) : durationCoupon !== null ? (
                  durationCoupon.famtFxrtGbcd === "2" ? (
                    priceFormatter.format(durationCoupon.famtFxrtVal.replaceAll(" ", "")) + "원"
                  ) : (
                    durationCoupon.famtFxrtVal.replaceAll(" ", "") + "%"
                  )
                ) : (
                  <></>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className="thd-live-coupon-login-container">
            <Typography
              textAlign="center"
              fontFamily="Roboto"
              fontSize="12px"
              lineHeight="14px"
              fontStyle="normal"
              fontWeight="700"
            >
              {/* {Counter(120)} */}
              {joinCoupon !== null ? (
                <>로그인하고 혜택받기</>
              ) : durationCoupon !== null ? (
                <>
                  {minuteConverter(delay)} 후 <br />
                  쿠폰 지급
                </>
              ) : (
                <></>
              )}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default LiveCouponIOS;
