import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import CustomLink from "../service/custom-link/customLink";
import { userState } from "../interface/MainInterface";
import * as AuthMain from "../service/auth/AuthMain";
import { HttpClientApi } from "../service/client-rest-api";
import { appConfig } from "../config/Config";
import "./Header.css";

interface propsType {
  userState: userState;
}

const Header = (props: propsType) => {
  let navigate = useNavigate();
  const clientApi = new HttpClientApi();
  const [openLeftMenu, setOpenLeftMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userPic, setUserPic] = React.useState<string>("");

  const handleDrawerOpen = () => setOpenLeftMenu(true);
  const handleDrawerClose = () => setOpenLeftMenu(false);

  const menuOpen = Boolean(anchorEl);
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.userState.isAuth) {
      setAnchorEl(event.currentTarget);
    } else {
      goLoginPage();
    }
  };
  const handleUserMenu = () => {
    setAnchorEl(null);
  };

  const goMyAccountPage = () => {
    navigate("/myaccount");
  };

  const goLoginPage = () => {
    navigate("/login");
  };

  const goHome = () => {
    // navigate("/");
    window.location.href = "/";
  };

  async function signOut() {
    const result = await AuthMain.signOut();
    if (result) window.location.href = "/";
  }

  const getUrl = async () => {
    if (props.userState.picture !== "") {
      const image_url = await clientApi.get_presigned_url("user-photos/" + props.userState.picture);
      // console.info("picture : ", userAttr.picture);
      // console.info("image_url : ", image_url);
      setUserPic(image_url.result_body.url);
    }
  };

  React.useEffect(() => {
    getUrl();
  }, []);

  const [headerDisPlay, setHeaderDisplay] = React.useState("none");

  React.useEffect(() => {
    // 화면 경로별 해더 감추기
    const pathName: string[] = window.location.pathname.split("/");
    if (pathName.length > 1) {
      if (pathName[1] === "loginFrame") {
        setHeaderDisplay("none");
      } else {
        setHeaderDisplay("flex");
      }
    } else {
      setHeaderDisplay("flex");
    }
  }, [window.location]);

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: "0", zIndex: "700", display: headerDisPlay }}>
      <AppBar id="Header-container" position="static">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <IconButton size="large" aria-label="menu" onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" align="center" className="Header-logo" onClick={goHome}>
              IT<span style={{ color: "red" }}>&</span>Live
            </Typography>
          </Stack>
          <Box>
            <IconButton
              onClick={handleAvatarClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={menuOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
            >
              {props.userState.isAuth ? (
                // <FaceIcon />
                <Avatar
                  sx={
                    userPic !== ""
                      ? {}
                      : props.userState.isAdmin
                      ? { width: 32, height: 32, backgroundColor: "#ef09e3" }
                      : { width: 32, height: 32, backgroundColor: "#07582e" }
                  }
                  src={userPic}
                >
                  {props.userState.nickName === "" ? props.userState.id[0] : props.userState.nickName[0]}
                </Avatar>
              ) : (
                <LoginIcon />
              )}
            </IconButton>
            <Menu id="basic-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleUserMenu} onClick={handleUserMenu}>
              {props.userState.isAuth && (
                <div key={1}>
                  <MenuItem onClick={goMyAccountPage}>My account</MenuItem>
                  <MenuItem onClick={signOut}>Logout</MenuItem>
                </div>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={openLeftMenu} onClose={handleDrawerClose}>
        <Box sx={{ width: 250 }} onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
          <List>
            <CustomLink to="/" pageName="홈으로" iconType="home" />
            {/* Admin */}
            {props.userState.isAdmin && (
              <>
                <hr />
                <CustomLink to="/channelmanagement" pageName="채널정보관리" iconType="live_tv" />
                {appConfig.progressStoreSelect ? (
                  <CustomLink to="/progresscode" pageName="진행점관리" iconType="storefront" />
                ) : (
                  <></>
                )}
                <CustomLink to="/setting" pageName="템플릿관리" iconType="brush" />
                {props.userState.isSuperAdmin ? (
                  <>
                    <CustomLink to="/codemanagement" pageName="공통코드관리" iconType="settings" />
                    <CustomLink to="/adminmanagement" pageName="관리자정보관리" iconType="manage_accounts" />
                    <CustomLink to="/devinfo" pageName="개발안내" iconType="import_contacts" />
                  </>
                ) : (
                  <></>
                )}
                <hr />
              </>
            )}
            {/* Client */}
            <CustomLink to="/clientchannels" pageName="방송목록조회" iconType="smart_display" />
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
