import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import MainDisplay from "../../service/live-display/MainDisplay";
import LiveCalender from "../../service/live-display/LiveCalender";
import VodMainList from "../../service/live-display/VodMainList";
import { userState, androidWebAppInterface } from "../../interface/MainInterface";
import Header from "../Header";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

const LiveHome = (props: propsType) => {
  const callBackLiveCalender = (command: any) => {};
  return (
    <>
      <Header userState={props.userState} />
      <Box sx={{ width: "100%", flexGrow: 1, px: 5, pb: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MainDisplay />
          </Grid>
          <Grid item xs={12}>
            <LiveCalender
              webViewStatus={props.webViewStatus}
              callBack={callBackLiveCalender}
              userState={props.userState}
            />
          </Grid>
          <Grid item xs={12}>
            <VodMainList webViewStatus={props.webViewStatus} userState={props.userState} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LiveHome;
