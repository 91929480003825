import React, { useEffect, useState, createRef, useRef, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import queryString from "query-string";

import Box from "@mui/material/Box";

// login
import AskLoginDialog from "../../../service/auth/AskLoginDialog";

// chat
import ChatVerNoSdk from "./ChatVerNoSdk";
import ChatVerVod from "./ChatVerVod";
import IoTCore from "../../../service/iot/IoTClient";

// ivs
import TestPlayer from "./TestPlayer";

// live menu
import LiveHeader from "./LiveHeader";
import LiveFooter from "./LiveFooter";
import LiveNotice from "./LiveNotice";
import LiveCoupon from "./LiveCoupon";
import ModalAskPassword from "./ModalAskPassword";

// api
import { userState, channelInfo, androidWebAppInterface, emptySetting } from "../../../interface/MainInterface";
import { HttpClientApi, CBroadParams, PutMetadataParams } from "../../../service/client-rest-api";

// common
import { appConfig } from "../../../config/Config";
import { CommonUtils } from "../../../service/common_utils";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

let channelParams: channelInfo = {
  broad_seq: "",
  broad_title: "",
  broad_desc: "",
  broad_status: "",
  broad_start_tm: "",
  broad_stop_tm: "",
  broad_notice: "",
  broad_prod_list: [],
  broad_cover_url: "",
  vod_url: "",
  vod_started_at: "",
  vod_start_sec: 0,
  vod_end_sec: 0,
  most_concurrent_view_timestamp: "",
  ivs_channel_arn: "",
  ivs_stream_key: "",
  ivs_ingest_endpoint: "",
  ivs_playback_url: "",
  chime_channel_arn: "",
  chime_admin_arn: "",
  total_likes: 0,
  total_views: 0,
  password: "",
  extra_type: "",
  progress_store: "",
  host_id: "",
  host_name: "",
  host_picture: "",
  channel_img: "",
  channel_img_url: "",
  channel_template: false,
  setting: emptySetting,
};

const Test = (props: propsType) => {
  const [cUtils] = useState(() => new CommonUtils());
  const { broadseq } = useParams(); // URL Parameter 로 방송순번 전달 받음

  const clientApi = new HttpClientApi(); // API

  const [info, setInfo] = useState(channelParams); // 채널정보

  // 최초 페이지 로딩
  useEffect(() => {
    loadChannelInfoFast();
  }, []);

  // 채널정보 조회
  const loadChannelInfoFast = async () => {
    if (broadseq !== undefined) {
      const param: CBroadParams = {
        broad_seq: broadseq,
      };

      const res = await clientApi.get_channel_info(param);
      setInfo(res.result_body);
    } else {
      console.error("The channel is undefined");
    }
  };

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    window.location.reload(); // IoT다시 맺기
  };

  return (
    <>
      <div className="viewer-root">
        <div className="Live-Layout-wrapper">
          <div className="Live-Layout-inner">
            <div className="Live-Swipe-content">
              <div className="VideoPlayer-wrapper color-snow">
                <TestPlayer playbackUrl={info.vod_url} />
              </div>
              <div className={"Tap-screen-wrapper color-snow"}>
                <div id="tab-screen-content" className="Tap-screen-content">
                  <div className="live-header-root"></div>
                  <div className="live-content-root">
                    <div className="live-content-wrapper">
                      <div className="live-content-chat"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test;
