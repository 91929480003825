import { useEffect, useState } from "react";
import { appConfig } from "../../config/Config";
import { CommonUtils } from "../../service/common_utils";
import "../live-menu/LiveHeader.css";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

interface propsType {
  setting: any;
  screenMode: string;
}

const TemplatePreview = (props: propsType) => {
  const [cUtils] = useState(() => new CommonUtils());
  const [logoSize, setLogoSize] = useState("2vmax");
  const [logoImgSize, setLogoImgSize] = useState("3vmax");

  const [hostImgSize, setHostImgSize] = useState("3.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setHostImgSize(`${props.setting.host_img_size}vmax`);
    else setHostImgSize(`${props.setting.host_img_v_size}vmax`);
  }, [props.screenMode, props.setting.host_img_size, props.setting.host_img_v_size]);

  const [titleTextSize, setTitleTextSize] = useState("2.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setTitleTextSize(`${props.setting.title_text_size}vmax`);
    else setTitleTextSize(`${props.setting.title_text_v_size}vmax`);
  }, [props.screenMode, props.setting.title_text_size, props.setting.title_text_v_size]);

  const [titleShadow, setTitleShadow] = useState("");
  useEffect(() => {
    if (props.setting.title_shadow) setTitleShadow("1px 1px 1px " + props.setting.title_shadow_color);
    else setTitleShadow("");
  }, [props.setting.title_shadow, props.setting.title_shadow_color]);

  const [statisticsTextSize, setStatisticsTextSize] = useState("2.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setStatisticsTextSize(`${props.setting.statistics_text_size}vmax`);
    else setStatisticsTextSize(`${props.setting.statistics_text_v_size}vmax`);
  }, [props.screenMode, props.setting.statistics_text_size, props.setting.statistics_text_v_size]);

  const [statisticsShadow, setStatisticsShadow] = useState("");
  useEffect(() => {
    if (props.setting.statistics_shadow) setStatisticsShadow("1px 1px 1px " + props.setting.statistics_shadow_color);
    else setStatisticsShadow("");
  }, [props.setting.statistics_shadow, props.setting.statistics_shadow_color]);

  const [iconSize, setIconSize] = useState("3.0vmax");
  useEffect(() => {
    if (props.screenMode === "W") setIconSize(`${props.setting.icon_size}vmax`);
    else setIconSize(`${props.setting.icon_v_size}vmax`);
  }, [props.screenMode, props.setting.icon_size, props.setting.icon_v_size]);

  useEffect(() => {}, [props.setting, props.screenMode]);

  function BroadLogo() {
    return (
      <>
        {props.setting.logo_img_url !== "" ? (
          <Box component="div" sx={{ width: "100%" }}>
            <CardMedia
              component="img"
              style={{
                width: "auto",
                height: logoImgSize,
                objectFit: "contain",
              }}
              image={props.setting.logo_img_url}
            />
          </Box>
        ) : (
          <Typography
            variant={"h6"}
            sx={{ textOverflow: "clip", overflow: "hidden", whiteSpace: "nowrap", mb: 0 }}
            gutterBottom
          >
            {appConfig.extra_type === "P001" ? (
              <>
                <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>현대백화점</span>
                <span style={{ color: "red", fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>&nbsp;VIP</span>
                <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>&nbsp;Live</span>
              </>
            ) : (
              <>
                <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>IT</span>
                <span style={{ color: "red", fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>&</span>
                <span style={{ fontSize: logoSize, textShadow: "1px 1px 1px gray" }}>LIVE</span>
              </>
            )}
          </Typography>
        )}
      </>
    );
  }

  // 전체화면 모드
  const handleFullscreen = () => {
    cUtils.toggelFullScreen();
  };

  // 전체화면 상태에 따른 아이콘 변경
  const [fullscreen, setFullscreen] = useState(false);
  const fullscreenchanged = (event: any) => {
    setFullscreen((fullscreen) => cUtils.isFullscreen());
  };

  // 전체화면 상태 이벤트 리스너
  window.addEventListener("fullscreenchange", fullscreenchanged, false);
  window.addEventListener("webkitfullscreenchange", fullscreenchanged, false);
  window.addEventListener("mozfullscreenchange", fullscreenchanged, false);

  return (
    <Box className="live-header color-snow" sx={{ width: "100%", visibility: "visible" }}>
      <Box component="div" sx={{ width: "100%", position: "relative" }}>
        <Box component="div" sx={{ width: "100%", display: "flex", position: "absolute", left: "0px", top: "-5px" }}>
          <Stack sx={{ width: "100%" }} direction="column" spacing={0}>
            {props.setting.logo ? <BroadLogo /> : <></>}
            <List
              sx={
                props.setting.logo
                  ? { maxWidth: "100%", padding: 0 }
                  : props.screenMode === "H"
                  ? { maxWidth: "50%", padding: 0 }
                  : { maxWidth: "70%", padding: 0 }
              }
            >
              <ListItem alignItems="flex-start" sx={{ padding: 0 }}>
                {props.setting.host_picture ? (
                  <ListItemAvatar sx={{ width: hostImgSize, height: hostImgSize, minWidth: "0px", marginRight: 1 }}>
                    <Avatar
                      alt="Host Icon"
                      sx={{ width: "100%", height: "100%" }}
                      src={"/images/host_img.png"}
                    ></Avatar>
                  </ListItemAvatar>
                ) : (
                  <></>
                )}
                <ListItemText
                  primary={
                    props.setting.title ? (
                      <Typography
                        sx={{
                          fontSize: titleTextSize,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          color: props.setting.title_text_color,
                          textShadow: titleShadow,
                        }}
                      >
                        {"방송 타이틀 영역입니다."}
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                  secondary={
                    props.setting.statistics ? (
                      <Typography
                        sx={{
                          fontSize: statisticsTextSize,
                          color: props.setting.statistics_text_color,
                          textShadow: statisticsShadow,
                        }}
                      >
                        <VideoCameraFrontIcon sx={{ fontSize: statisticsTextSize }} className="svc-icon" />{" "}
                        <span className="svc-text">{"200"}</span>{" "}
                        <FavoriteBorderOutlinedIcon sx={{ fontSize: statisticsTextSize }} className="svc-icon" />
                        <span className="svc-text">{"10,000"}</span>
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                  secondaryTypographyProps={{
                    color: "snow",
                  }}
                />
              </ListItem>
            </List>
          </Stack>
        </Box>
        <Box component="div" sx={{ display: "flex", position: "absolute", right: "0px", top: "0px" }}>
          <Stack direction="row" spacing={2}>
            {/* 볼륨 컨트롤 */}
            <IconButton sx={{ display: "flex", padding: 0 }}>
              <VolumeUpIcon sx={{ fontSize: iconSize }} className="color-snow" />
            </IconButton>
            {/* 해상도 선택 */}
            <IconButton sx={{ display: "flex", padding: 0 }}>
              <VideoSettingsIcon sx={{ fontSize: iconSize }} className="color-snow" />
            </IconButton>
            {/* fullscreen */}
            <IconButton
              sx={cUtils.isSupportFullScreen() ? { padding: 0, display: "flex" } : { padding: 0, display: "flex" }}
              onClick={handleFullscreen}
            >
              {fullscreen ? (
                <CloseFullscreenIcon sx={{ fontSize: iconSize }} className="color-snow" />
              ) : (
                <FullscreenIcon sx={{ fontSize: iconSize }} className="color-snow" />
              )}
            </IconButton>
            <IconButton sx={{ padding: 0 }}>
              <CloseIcon
                sx={{ fontSize: iconSize }}
                className="color-snow"
                onClick={() => {
                  window.close();
                }}
              />
            </IconButton>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default TemplatePreview;
