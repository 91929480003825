import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import ClientChannelCard from "./ChannelCard";
import ModalChatName from "../chat/ModalChatName";
import { HttpClientApi, GetChannelListParams } from "../client-rest-api";
import { itemChannel, userState } from "../../interface/MainInterface";
import { CommonUtils } from "../common_utils";

interface propsType {
  webViewStatus: string;
  callBack: any;
  userState: userState;
}

const LiveCalender = (props: propsType) => {
  const cUtils = new CommonUtils();
  const clientApi = new HttpClientApi();

  const [loading, setLoading] = useState(false);
  const [liveCalenderDates, setLiveCalenderDates] = useState([] as any); // 달력
  const [selectedDate, setSelectedDate] = useState("");
  const [liveCalenderBroadRows, setLiveCalenderBroadRows] = useState<itemChannel[]>([]);

  useEffect(() => {
    loadLiveCalenderDates();
  }, []);

  const loadLiveCalenderDates = () => {
    const formDays = 3;
    const endDays = 7;
    const resultDayList: any[] = cUtils.getAddDateList(formDays, endDays);
    const firstSelectedDate = resultDayList[formDays].broad_date;
    setLiveCalenderDates(resultDayList);
    setSelectedDate(firstSelectedDate);
    loadLiveCalenderBroadList(firstSelectedDate);
  };

  const handleLiveCalenderChange = (event: React.SyntheticEvent, newDate: string) => {
    setSelectedDate(newDate);
    loadLiveCalenderBroadList(newDate);
  };

  const loadLiveCalenderBroadList = async (search_date: string) => {
    setLoading(true);
    // 어드민, 일반 사용자 필터
    const adminStatus = ["CREATE", "START", "READY", "STOP", "VOD"];
    const userStatus = ["READY", "START", "VOD"];
    const sort = "ASCEND";

    let param: GetChannelListParams = {
      broad_date: [search_date],
      broad_status: userStatus,
      sort: sort,
    };

    if (props.userState.isAdmin) param.broad_status = adminStatus;

    const res = await clientApi.get_channel_list(param);
    setLiveCalenderBroadRows(res.result_body);
    setLoading(false);
  };

  const [modalCommand, setModalCommand] = useState("CLEAR_COMMAND");
  const [selectedBroadSeq, setSelectedBroadSeq] = useState("");
  const channelCardCallBack = (command: any, freeForm: any) => {
    setSelectedBroadSeq("");
    switch (command) {
      case "OPEN_CHAT_NAME_MODAL":
        setModalCommand("OPEN_CHAT_NAME_MODAL");
        setSelectedBroadSeq(freeForm);
        setTimeout(() => {
          setModalCommand("CLEAR_COMMAND");
        }, 100);

        break;
      default:
        console.error("not support command : ", command);
    }
  };

  const modalProcDone = (res: any) => {
    const seq = selectedBroadSeq;
    setSelectedBroadSeq("");
    if (res === "SUCCESS") window.location.href = "/live/" + seq;
  };

  return (
    <>
      <Stack spacing={0} direction="column">
        <h2>Live 캘린더</h2>
        {selectedDate !== "" && (
          <Tabs
            value={selectedDate}
            onChange={handleLiveCalenderChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Live calendar"
            sx={{ m: 0, p: 0, diplay: "flex", justifyContent: "space-between" }}
          >
            {liveCalenderDates.map((dates: any) => (
              <Tab
                key={dates.broad_date}
                value={dates.broad_date}
                label={
                  <React.Fragment>
                    {dates.broad_day}
                    <br />
                    <h3>
                      <b>{dates.day_text}</b>
                    </h3>
                  </React.Fragment>
                }
                sx={{ p: 0, minWidth: 70, flexGrow: 1 }}
              />
            ))}
          </Tabs>
        )}
      </Stack>
      <Box sx={{ width: "100%", flexGrow: 1, pt: 2 }}>
        <Grid container spacing={2}>
          {liveCalenderBroadRows.map((item: itemChannel, index: number) => {
            return (
              <ClientChannelCard
                key={item.broad_seq}
                item={item}
                webViewStatus={props.webViewStatus}
                userState={props.userState}
                callBack={channelCardCallBack}
              />
            );
          })}
        </Grid>
      </Box>
      <ModalChatName modalCommand={modalCommand} callBack={modalProcDone} />
    </>
  );
};

export default LiveCalender;
