import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import ClientChannelCard from "./ChannelCard";
import { HttpClientApi, CBroadParams } from "../client-rest-api";
import { itemChannel, userState } from "../../interface/MainInterface";

interface propsType {
  webViewStatus: string;
  userState: userState;
}

const VodMainList = (props: propsType) => {
  const clientApi = new HttpClientApi();
  const [vodChannelList, setVodChannelList] = useState<itemChannel[]>([]);

  const channelCardCallBack = (command: any, freeForm: any) => {};
  useEffect(() => {
    getChannelist();
  }, []);

  const vodList: any = [];
  const getChannelist = async () => {
    let list = [];
    for (const vod_seq of vodList) {
      const param: CBroadParams = {
        broad_seq: vod_seq,
      };
      const res = await clientApi.get_channel_info(param);
      // console.log("res.result_body : ", res.result_body);
      list.push(res.result_body);
    }
    setVodChannelList(list);
  };

  return (
    <>
      <Stack spacing={0} direction="column">
        <h2>지난방송보기</h2>
      </Stack>
      <Box sx={{ width: "100%", flexGrow: 1, pt: 2 }}>
        <Grid container spacing={2}>
          {vodChannelList.map((item: itemChannel, index: number) => {
            return (
              <ClientChannelCard
                key={item.broad_seq}
                item={item}
                webViewStatus={props.webViewStatus}
                userState={props.userState}
                callBack={channelCardCallBack}
              />
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default VodMainList;
