import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";

interface propsType {
  dataRows: any;
  chartTitle: string;
  labelTitle: string;
  xName: string;
  yName: string;
  lineColor: string;
}

interface IGridCols {
  label: string;
  value: number;
}

Chart.register(CategoryScale);

const LineChart = (props: propsType) => {
  const convertTime = (input: string) => {
    return input.substring(8, 10) + ":" + input.substring(10, 12);
  };

  const [chartData, setChartData] = useState({
    labels: props.dataRows.map((data: IGridCols) => convertTime(data.label)),
    datasets: [
      {
        label: props.labelTitle,
        data: props.dataRows.map((data: IGridCols) => data.value),
        borderColor: props.lineColor,
      },
    ],
  });

  useEffect(() => {
    if (props.dataRows.length > 0) {
      let newDataRows = [...props.dataRows];
      setChartData({
        labels: newDataRows.map((data: IGridCols) => convertTime(data.label)),
        datasets: [
          {
            label: props.labelTitle,
            data: props.dataRows.map((data: IGridCols) => data.value),
            borderColor: props.lineColor,
          },
        ],
      });
    } else {
      setChartData({
        labels: [],
        datasets: [{ label: props.labelTitle, data: [], borderColor: props.lineColor }],
      });
    }
  }, [props.dataRows]);

  return (
    <div className="chart-root">
      <Line
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: props.chartTitle,
            },
            legend: {
              display: false,
              position: "top",
            },
          },
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              title: {
                display: false,
                text: props.xName,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: props.yName,
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
